import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import defaultAvatar from "assets/img/placeholder.jpg";
import {
  getValue,
  EMAIL_PATTERN,
  isEmpty,
  isValueValid,
  ALPHABETS_ONLY,
  ALPHABETS_ONLY_WITH_SPACE,
  ALPHABETS_NUMBER_ONLY,
} from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/squad";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import {default as CustomInput} from "components/SquadTypeCustomInput/SquadTypeCustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { findLast } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import DeleteIcon from "@material-ui/icons/Delete";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const ImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/ImageUpload.js"
  )
);
const MulImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/MultipleImage.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const event_type =[{
  label:"Match",
  value:1,
},{
  label:"Training",
  value:3,
},{
  label:"Social",
  value:4,
}]
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const SquadContainer = (props) => {
  const [alert, setAlert] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const [master, setMaster] = React.useState({});
  const [leagues, setLeagues] = React.useState([]);
  const [adminList, setAdminList] = React.useState([]);
  const [selectedAdminList, setSelectedAdminList] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState("");
  const [selectedSport, setSelectedSport] = React.useState("");
  const [selectedLeague, setSelectedLeague] = React.useState("");
  const [events,setEvents] = React.useState([]);
  const [squadStats,setSquadStats] = React.useState([]);
  const [squadTitle,setSquadTitle] = React.useState("")
  const { register, handleSubmit, reset } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const [classicModal, setClassicModal] = React.useState(false);
  const [classicModalAdd, setClassicModalAdd] = React.useState(false);
  const [classicEventModal, setclassicEventModal] = React.useState(false);
  const [classicSquadStatModal, setClssicSquadStatModal] = React.useState(false);
  const [classicEditModal, setClassicEditModal] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [profile_pic, setProfilePic] = React.useState(null);
  const [squadEvent, setSquadEvent] = useState([
    { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
  ]);
  const [squadstat, setSquadStat] = useState([
    { id:1, squad_stat: ""},
  ]);
  const classes = useStyles();
  const classesalert = useStylesAlert();
  useEffect(() => {
    (async () => {
      var squads = await squadService.ShowAllSport();
      if (squads && squads.data) {
        setData(
          squads.data.map((prop, key) => {
            return {
              id: key,
              image: <img src={prop.image} alt={"no Image"} style={{maxWidth:"47px"}}/>,
              name: prop.title,
              event_type: <div className="actions-right" key={key}>
               <Button
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
                onClick={()=>{
                  setEvents(prop.event_type)
                  setSquadTitle(prop.title)
                  setclassicEventModal(true)
                }}
              >
                Show Event Type
              </Button>
            </div>,
              squad_stats:<div className="actions-right" key={key}><Button
              // className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onClick={()=>{
                setSquadTitle(prop.title)    
                  setSquadStats(prop.squad_stats[0]?.stat_names)
                setClssicSquadStatModal(true)
              }}
            >
              Show Squad Statistics
            </Button></div>,
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={key}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      reset({
                        name: prop.label,
                      });
                      setActiveData({ ...prop });
                      const filterData= prop.event_type && prop.event_type.map((element,index)=>{
                        return { 
                          id: element.id, 
                          event_name: element.name, 
                          event_type: element.type, 
                          event_image: element.image,
                          image_url:element.image }
                      })
                      const stat_names = prop.squad_stats;
                      const squadStatFilterData= stat_names.length > 0 && stat_names[0].stat_names.map((elements,index)=>{
                        return {  id:stat_names[0].id,squad_stat: elements}
                      })
                      setSquadEvent(filterData ? filterData :[
                        { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
                      ])
                      setSquadStat(squadStatFilterData ? squadStatFilterData : [
                        { squad_stat: ""},
                      ])
                      // setSelectedColor(prop.color_id);
                      setSelectedSport(prop.title);
                      // setSelectedLeague(prop.league_id ? prop.league_id : "");
                      // setSelectedAdminList(prop.admin_ids.split(","))
                      setClassicEditModal(true);
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Edit />
                  </Button>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      warningWithConfirmAndCancelMessage({ id: prop.id })
                    }
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }

      var masterData = await masterService.getMasterData();
      if (masterData && masterData.data) {
        setMaster(masterData.data);
      }
      var leagueData = await masterService.getLeagueList();
      if (leagueData && leagueData.data) {
        setLeagues(leagueData.data);
      }
      var userData = await userService.getUserList();
      if (userData && userData.data) {
        setAdminList(userData.data);
      }
    })();
  }, []);
  const reload = async () => {
    var squads = await squadService.ShowAllSport();
    if (squads && squads.data) {
      setData(
        squads.data.map((prop, key) => {
          return {
            id: key,
            image: <img src={prop.image} alt={"no Image"} style={{maxWidth:"47px"}} />,
            name: prop.title,
            event_type: <div className="actions-right" key={key}>
             <Button
              // className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onClick={()=>{
                setEvents(prop.event_type)
                setSquadTitle(prop.title)
                setclassicEventModal(true)
              }}
            >
              Show Event Type
            </Button>
          </div>,
            squad_stats:<div className="actions-right" key={key}><Button
            // className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            addButtonProps={{
              color: "rose",
              round: true,
            }}
            onClick={()=>{
              setSquadTitle(prop.title)
              setSquadStats(prop.squad_stats[0].stat_names)
              setClssicSquadStatModal(true)
            }}
          >
            Show Squad Statistics
          </Button></div>,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={key}>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    reset({
                      name: prop.label,
                    });
                    setActiveData({ ...prop });
                    const filterData= prop.event_type && prop.event_type.map((element,index)=>{
                      return { 
                        id: element.id, 
                        event_name: element.name, 
                        event_type: element.type, 
                        event_image: element.image,
                        image_url:element.image }
                    })
                    const stat_names = prop.squad_stats;
                    const squadStatFilterData= stat_names.length > 0 && stat_names[0].stat_names.map((elements,index)=>{
                      return {  id:stat_names[0].id,squad_stat: elements}
                    })
                    setSquadEvent(filterData ? filterData :[
                      { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
                    ])
                    setSquadStat(squadStatFilterData ? squadStatFilterData : [
                      { squad_stat: ""},
                    ])
                    // setSelectedColor(prop.color_id);
                    setSelectedSport(prop.title);
                    // setSelectedColor(prop.color_id);
                    // setSelectedSport(prop.sport_id);
                    // setSelectedLeague(prop.league_id ? prop.league_id : "");
                    // setSelectedAdminList(prop.admin_ids.split(","))
                    setClassicEditModal(true);
                  }}
                  color="warning"
                  className="edit"
                >
                  <Edit />
                </Button>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    warningWithConfirmAndCancelMessage({ id: prop.id })
                  }
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>{" "}
              </div>
            ),
          };
        })
      );
    }
  };
  const OnEditFunction = async (e) => {
    e.preventDefault();
    const findisBlank = squadEvent.map((squadEle)=>{
      if(squadEle.event_name == ""){
        return true
      }else if(!squadEle.event_type){
        return true
      }else if(!squadEle.event_image){
        return true
      }
    })
    const findisBlankStat = squadstat.map((squadEle)=>{
      if(squadEle.squad_stat.trim() == ""){
        return true
      }
    })
  const filterData =findisBlank && findisBlank.length > 0 ? findisBlank.filter((ele)=>ele):[]
  const filterDataStat =findisBlankStat && findisBlankStat.length > 0 ? findisBlankStat.filter((ele)=>ele):[]

    if(filterData && filterData.length > 0 && filterData[0]){
       squadEvent.map((element)=>{
           if(element.event_name.trim() == ""){
            props.toastComponentAction.showErrorMessage(
              true,
              "Event Name is Required."
            );

           }else if(!element.event_type){
              props.toastComponentAction.showErrorMessage(
                true,
                "Event Type is Required."
              );
           }else if(!element.event_image) {
            props.toastComponentAction.showErrorMessage(
              true,
              "Event Image is Required."
            );
           }
       })
    }else if (isEmpty(selectedSport)) {
      props.toastComponentAction.showErrorMessage(
        true,
        "Squad Type Name is compulsary."
      );
    } else if(filterDataStat && filterDataStat.length > 0 && filterDataStat[0]){
      squadstat.map((squadEle)=>{
        if(squadEle.squad_stat.trim() == ""){
          props.toastComponentAction.showErrorMessage(
            true,
            "Squad Statistics is Required."
          );
        }
      })
    } else {
      const submitData ={
        squad_type_title:selectedSport,
        squad_type_image:"",
        squad_event:squadEvent.map((alis)=>{
          if (alis.event_image.name) {
            return {
              id:alis.id,
              event_name:alis.event_name,
              event_type:alis.event_type,
              event_type_image:alis.event_image,
            }
          }else{
            return {
              id:alis.id,
              event_name:alis.event_name,
              event_type:alis.event_type,
          }
        }
      }),
      squad_stat:squadstat.length > 0 && squadstat.map((ele)=>ele.squad_stat)
    }

    
      var final = new FormData();
      if(selectedSport){
        final.append("squad_type_title",submitData.squad_type_title)
      }
      if(profile_pic){
        submitData["squad_type_image"] = profile_pic
        final.append("squad_type_image",profile_pic)
      }
      Array.from(
        submitData.squad_event.map((element) => {
           if(element.event_type_image){
             return element.event_type_image;  
           }
        })
        
      ).forEach((file) => {
        if(file){
          final.append("event_type_image", file);
        }
      });
      final.append("squad_event",JSON.stringify(squadEvent.map((eles)=>{
        return {
          id:eles.id,
          event_name:eles.event_name,
          event_type:eles.event_type,
          event_image:eles.event_image ?? ""
        }
      })))
      final.append("squad_stat", submitData.squad_stat);
      try {
        props.loaderActions.showLoader();
        var resp = await squadService.UpdateSquadType(activeData.id,final)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload();
        setClassicEditModal(false);
        setSquadEvent([
          { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
        ])
        setSquadStat([
          {  squad_stat: ""},
        ])
        setSelectedSport("")
        setProfilePic(null)
      } catch (err) {
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
    }
  };
  const onSubmitAdd = async (data) => {
    const findisBlank = squadEvent.map((squadEle)=>{
      if(squadEle.event_name == ""){
        return true
      }else if(!squadEle.event_type){
        return true
      }else if(!squadEle.event_image){
        return true
      }
    })
    if(profile_pic == null){
      props.toastComponentAction.showErrorMessage(
        true,
        "Squad Type Image is Required."
      );
      return false;
    }
    const findisBlankStat = squadstat.map((squadEle)=>{
      if(squadEle.squad_stat.trim() == ""){
        return true
      }
    })
  const filterData =findisBlank && findisBlank.length > 0 ? findisBlank.filter((ele)=>ele):[]
  const filterDataStat =findisBlankStat && findisBlankStat.length > 0 ? findisBlankStat.filter((ele)=>ele):[]
    if(filterData && filterData.length > 0 && filterData[0]){
       squadEvent.map((element)=>{
           if(element.event_name.trim() == ""){
            props.toastComponentAction.showErrorMessage(
              true,
              "Event Name is Required."
            );

           }else if(!element.event_type){
              props.toastComponentAction.showErrorMessage(
                true,
                "Event Type is Required."
              );
           }else if(!element.event_image) {
            props.toastComponentAction.showErrorMessage(
              true,
              "Event Image is Required."
            );
           }
       })
    }else if (isEmpty(selectedSport)) {
      props.toastComponentAction.showErrorMessage(
        true,
        "Squad Type Name is compulsary."
      );
    }else if(filterDataStat && filterDataStat.length >0 && filterDataStat[0]){
      props.toastComponentAction.showErrorMessage(
        true,
        "Squad Statistics is Required."
      );
    }else {
      const submitData ={
        squad_type_title:selectedSport,
        squad_type_image:profile_pic,
        squad_event:squadEvent.map((alis)=>{
          return {
            event_name:alis.event_name,
            event_type:alis.event_type,
            event_type_image:alis.event_image
          }
        }),
        squad_stat:squadstat.length > 0 && squadstat.map((ele)=>ele.squad_stat)
      }
      var final = new FormData();
      final.append("squad_type_title",submitData.squad_type_title)
      final.append("squad_type_image",profile_pic)
      Array.from(
        submitData.squad_event.map((element) => {
          return element.event_type_image;
        })
        
      ).forEach((file) => {
        final.append("event_type_image", file);
      });
      final.append("squad_event",JSON.stringify(submitData.squad_event.map((eles)=>{
        return {
          event_name:eles.event_name,
          event_type:eles.event_type,
        }
      })))
      final.append("squad_stat", submitData.squad_stat);
      try {
        props.loaderActions.showLoader();
        var resp = await squadService.CreateSquadType(final);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload();
        setClassicModalAdd(false);
        setSquadEvent([
          { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
        ])
        setSquadStat([
          {  squad_stat: ""},
        ])
        setSelectedSport("")
        setProfilePic(null)
        setActiveData({})
        setSubmitted(true)
      } catch (err) {
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
    }
  };
  const warningWithConfirmAndCancelMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesalert.button + " " + classesalert.success}
        cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to revert this!
      </SweetAlert>
    );
  };
  const successDelete = async (data) => {
    props.loaderActions.showLoader();
    var resp = await squadService.DeleteSquadType({ squad_type_id: data.id });
    props.loaderActions.hideLoader();
    props.toastComponentAction.showSuccessMessage(true, resp.message);
    reload();
    hideAlert();
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const handleInputChange = (index, event,name) => {
    const values = [...squadEvent];
    if(name == "event_image" || name == "image_url"){
      values[index][name] = event;
    }else{
      values[index][name] = event.target.value;
    }
    setSquadEvent(values);
  };
  const handlerSquadStateInput = (index, event) => {
    const values = [...squadstat];
    values[index]["squad_stat"]= event.target.value;
    setSquadStat(values);
  };

  return (
    <>
     {alert}
      <GridContainer
        justify="space-between" // Add it here :)
        container
        >
        <GridItem></GridItem>
        <GridItem>
          <div>
            <Button
              color="primary"
              className={classes.marginRight}
              onClick={(e) => {
                setSelectedColor("");
                setSelectedSport("");
                setSelectedLeague("");
                setSelectedAdminList([]);
                setClassicModalAdd(true);
                setSquadEvent([
                  { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
                ])
                setSquadStat([{squad_stat:""}])
              }}
              >
              Add Squad Type
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Squad Type List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Squad Type Image",
                    accessor: "image",
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  {
                    Header: "Event Type",
                    accessor: "event_type",
                  },{
                    Header: "Squad Stats",
                    accessor: "squad_stats",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicEditModal}
        keepMounted
        onClose={() => setClassicEditModal(false)}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            
            onClick={() => setClassicEditModal(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Edit Squad Type</p>
        </DialogTitle>
        <form onSubmit={OnEditFunction}>
          <DialogContent>
            <ImageUpload
             defaultPic={activeData.image}
              avatar
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onChangeFile={(file) => {
                setProfilePic(file);
              }}
              changeButtonProps={{
                color: "rose",
                round: true,
              }}
              removeButtonProps={{
                color: "danger",
                round: true,
              }}
              button_text="Add Squad Badge"
              />
            <CustomInput
              name="titles"
              inputrefs={register2({ required: true })}
              labelText="Squad Type Name"
              id="titles"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              // focused={true}
              defaultValue={activeData.title}
              value={selectedSport}
              onChange={(e)=>{
                setSelectedSport(e.target.value)
              }}
              />
            <div className="addSquadEventContainer">
              <h1>Squad Event</h1>
              <Button
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => {
                  const arrayUniqueSquadStat= [...new Set(squadEvent.map(item =>item.event_name.toLowerCase()).filter((ele)=>ele))];
                   if(!(squadEvent.length == arrayUniqueSquadStat.length) ){
                    props.toastComponentAction.showErrorMessage(
                      true,
                      "Please Avoid Duplicate Event Name"
                    );
                   }else{
                    const allValues = [...squadEvent];
                    allValues.push({
                      event_name: "",
                      event_type: "",
                      event_image: "",
                      image_url:""
                    });
                    setSquadEvent([...allValues]);
                   }
                 
                }}
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
              >
                Add Event Type
              </Button>
            </div>
            {squadEvent && squadEvent.map((squadev, index) => {
              return (
                <div className="eventTypDetails" key={index}>
                  <CustomInput
                    name={`event_name`}
                    inputrefs={register2({ required: true })}
                    labelText="Squad Event Name"
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                    }}
                    onChange={(e)=>{
                      const inputIndex= squadEvent.map((element,index)=>element.event_name.toLowerCase().includes(e.target.value.toLowerCase()))
                      if(inputIndex.find((ele)=>ele)){
                        props.toastComponentAction.showErrorMessage(
                          true,
                          "Please Avoid Duplicate Event Name"
                        );
                      }
                      handleInputChange(index,e,"event_name")
                    }}
                    value={squadev.event_name}
                  />
                   <FormControl   style={{width:"160px",paddingBottom:"-10px"}}>
                  <InputLabel>Select Sport</InputLabel>
                <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={event_type.find((ev)=>ev.value == squadev.event_type) ? event_type.find((ev)=>ev.value == squadev.event_type).value : ''}
                    onChange={(e)=>{
                      handleInputChange(index,e,"event_type")}}
                    label="Event Type"
                    // multiple
                    // disabled
                  >
                    {event_type && event_type.length > 0
                      ? event_type.map((art,index) => {
                          return (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={art.value}
                            >
                              {art.label}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                  </FormControl>
                  <MulImageUpload
                    avatar={squadev.image_url}
                    addButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    onChangeFile={(file,url) => {
                    
                      handleInputChange(index,url,"image_url")
                      handleInputChange(index,file,"event_image")
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true,
                    }}
                    button_text="Add Event Image"
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const values = [...squadEvent];
                      const filterArray = values.filter(
                        (elem, indexs) => indexs !== index);
                      if(!filterArray.length <= 0){
                        setSquadEvent([...filterArray]);
                      }
                    }}
                  />
                </div>
              );
            })}
             <div className="addSquadEventContainer">
              <h1>Squad Statistics:</h1>
              <Button
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => {
                  const arrayUniqueSquadStat= [...new Set(squadstat && squadstat.map(item =>item.squad_stat.toLowerCase()).filter((ele)=>ele))];
                   if(!(squadstat.length == arrayUniqueSquadStat.length)){
                    props.toastComponentAction.showErrorMessage(
                      true,
                      "Please Avoid Duplicate Squad Statics"
                    );
                   }else{
                    const allValues = [...squadstat];
                    allValues.push({
                      id:allValues[0].id,
                      squad_stat: "",
                    });
                    setSquadStat([...allValues]);
                   }           
                
                }}
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
              >
                Add Squad Statistics
              </Button>
            </div>
            {squadstat.map((squadev, index2) => {
              return (
                <div className="eventTypDetails" key={index2}>
                  <CustomInput
                    name={`squad_state`}
                    inputrefs={register2({ required: true })}
                    labelText="Squad Statistics Name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                    }}
                    onChange={(e)=>{
                      const index= squadstat.map((element,index)=>element.squad_stat.toLowerCase().includes(e.target.value.toLowerCase()))
                      if(index.find((ele)=>ele)){
                        props.toastComponentAction.showErrorMessage(
                          true,
                          "Please Avoid Duplicate Squad Statics"
                        );
                      }
                      handlerSquadStateInput(index2,e)
                    }}
                    value={squadev.squad_stat}
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const values = [...squadstat];
                      const filterArray = values.filter(
                        (elem, indexs) => indexs !== index2);
                      if(!filterArray.length <= 0){
                        setSquadStat([...filterArray]);
                      }
                    }}
                  />
                </div>
              );
            })}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() => {
                setClassicEditModal(false)
              }
              }
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Edit Model End*/}
      {/*Add Model Start*/}
      <Dialog
        fullWidth
        open={classicModalAdd}
        keepMounted
        onClose={() => {
          setSquadEvent([
            { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
          ])
          setSquadStat([
            {  squad_stat: ""},
          ])
          setSelectedSport("")
          setClassicModalAdd(false)
          setSubmitted(true)
        }}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModalAdd(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Add Squad Type</p>
        </DialogTitle>
        <form onSubmit={handleSubmit2(onSubmitAdd)}>
          <DialogContent>
            <ImageUpload
              avatar
              defaultValue={defaultAvatar}
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onChangeFile={(file) => {
                setProfilePic(file);
              }}
              changeButtonProps={{
                color: "rose",
                round: true,
              }}
              removeButtonProps={{
                color: "danger",
                round: true,
              }}
              ClearImage={submitted}
              button_text="Add Squad Badge"
              />
            <CustomInput
              name="name"
              inputrefs={register2({ required: true, validate:(value)=>{
                if(value.trim() === ""){
                  return "* Mandatory Field"
                }
              } })}
              labelText="Name"
              id="name2"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              defaultValue=""
              value={selectedSport}
              onChange={(e)=>setSelectedSport(e.target.value)}
              />
            <div className="addSquadEventContainer">
              <h1>Squad Event</h1>
              <Button
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => {
                  const arrayUniqueSquadStat= [...new Set(squadEvent.map(item =>item.event_name.toLowerCase()).filter((ele)=>ele))];
                   if(!(squadEvent.length == arrayUniqueSquadStat.length) ){
                    props.toastComponentAction.showErrorMessage(
                      true,
                      "Please Avoid Duplicate Event Name"
                    );
                   }else{
                    const allValues = [...squadEvent];
                    allValues.push({
                      id: squadEvent.length + 1,
                      event_name: "",
                      event_type: "",
                      event_image: "",
                      image_url:""
                    });
                    setSquadEvent([...allValues]);
                   }
                 
                }}
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
              >
                Add Event Type
              </Button>
            </div>
            {squadEvent.map((squadev, index) => {
              return (
                <div className="eventTypDetails" key={index}>
                  <CustomInput
                    name={`event_name`}
                    inputrefs={register2({ required: true, validate:(value)=>{
                      if(value.trim() === ""){
                        return "* Mandatory Field"
                      }
                    } })}
                    labelText="Squad Event Name"
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                    }}
                    onChange={(e)=>{
                      const inputIndex= squadEvent.map((element,index)=>element.event_name.toLowerCase().includes(e.target.value.toLowerCase()))
                      if(inputIndex.find((ele)=>ele)){
                        props.toastComponentAction.showErrorMessage(
                          true,
                          "Please Avoid Duplicate Event Name"
                        );
                      }
                      handleInputChange(index,e,"event_name")
                    }}
                    value={squadev.event_name}
                  />
                   <FormControl   style={{width:"160px",paddingBottom:"-10px"}}>
                  <InputLabel>Select Sport</InputLabel>
                <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={event_type.find((ev)=>ev.value == squadev.event_type) ? event_type.find((ev)=>ev.value == squadev.event_type).value : ''}
                    onChange={(e)=>{
                      handleInputChange(index,e,"event_type")}}
                    label="Event Type"
                    // multiple
                    // disabled
                  >
                    {event_type && event_type.length > 0
                      ? event_type.map((art,index) => {
                          return (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={art.value}
                            >
                              {art.label}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                  </FormControl>
                  <MulImageUpload
                    avatar={squadev.image_url}
                    addButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    onChangeFile={(file,url) => {
                    
                      handleInputChange(index,url,"image_url")
                      handleInputChange(index,file,"event_image")
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true,
                    }}
                    ClearImage={submitted}
                    button_text="Add Event Image"
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const values = [...squadEvent];
                      const filterArray = values.filter(
                        (elem, indexs) => indexs !== index);
                      if(!filterArray.length <= 0){
                        setSquadEvent([...filterArray]);
                      }
                    }}
                  />
                </div>
              );
            })}
             <div className="addSquadEventContainer">
              <h1>Squad Statistics:</h1>
              <Button
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => {
                  const arrayUniqueSquadStat= [...new Set(squadstat.map(item =>item.squad_stat.toLowerCase()).filter((ele)=>ele))];
                   if(!(squadstat.length == arrayUniqueSquadStat.length)){
                    props.toastComponentAction.showErrorMessage(
                      true,
                      "Please Avoid Duplicate Squad Statics"
                    );
                   }else{
                    const allValues = [...squadstat];
                    allValues.push({
                      squad_stat: "",
                    });
                    setSquadStat([...allValues]);
                   }           
                
                }}
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
              >
                Add Squad Statistics
              </Button>
            </div>
            {squadstat && squadstat.map((squadev, index2) => {
              return (
                <div className="eventTypDetails" key={index2}>
                  <CustomInput
                    name={`squad_state`}
                    inputrefs={register2({ required: true, validate:(value)=>{
                      if(value.trim() === ""){
                        return "* Mandatory Field"
                      }
                    } })}
                    labelText="Squad Statistics Name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                    }}
                    onChange={(e)=>{
                      const index= squadstat.map((element,index)=>element.squad_stat.toLowerCase().includes(e.target.value.toLowerCase()))
                      if(index.find((ele)=>ele)){
                        props.toastComponentAction.showErrorMessage(
                          true,
                          "Please Avoid Duplicate Squad Statics"
                        );
                      }
                      handlerSquadStateInput(index2,e)
                    }}
                    value={squadev.squad_stat}
                  />
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const values = [...squadstat];
                      const filterArray = values.filter(
                        (elem, indexs) => indexs !== index2);
                      if(!filterArray.length <= 0){
                        setSquadStat([...filterArray]);
                      }
                    }}
                  />
                </div>
              );
            })}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() => setClassicModalAdd(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Add Model End*/}



      {/* Show Event Type */}
      <Dialog
      maxWidth="lg"
        fullWidth
        open={classicEventModal}
        keepMounted
        onClose={() => setclassicEventModal(false)}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setclassicEventModal(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Event Type</p> <span style={{fontSize:"16px"}}>Squad Type: <span style={{fontSize:"16px",color:"#404040"}}>{squadTitle}</span></span>
        </DialogTitle>
       <DialogContent>
       <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>
                Event Image
              </TableCell>
              <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>
                Event Name
              </TableCell>
              <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>
                Event Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events && events.length > 0  && events.map((element,index)=>{
              return <TableRow key={index}>

                <TableCell className={classes.tableCell}>
                  <img src={element.image} alt="no Event Image" style={{minWidth:"60px",maxWidth:"60px",minHeight:"50px",maxHeight:"50px"}}/>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {element.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                {element.type == 1 ? "Match" : element.type == 3 ? "Training":element.type == 4 ? "Social" :""}
                </TableCell>
              </TableRow>
            })}
          </TableBody>
       </Table>
        {/* <div className="eventType_heading">
          <h3>Event Image</h3>
          <h3>Event Name</h3>
          <h3>Event Type</h3>
          
        </div>
        <div className="eventType_container">
        {
          events && events.length > 0  && events.map((element,index)=>{
            return <div className="eventtypeDialog_container" key={index}>
              <div style={{width:"70px",height:"70px",textAlign:"center"}}>
              <img src={element.image} alt="no Event Image" style={{minWidth:"60px",maxWidth:"60px",minHeight:"50px",maxHeight:"50px"}}/>

              </div>
              <div>

              <h5>{element.name}</h5>
              </div>
              <div className="text-center">
              <h5 style={{width:"20px"}}>{element.type == 1 ? "Match" : element.type == 3 ? "Training":element.type == 4 ? "Social" :""}</h5>
              </div>

        </div>
          })
        }
        </div> */}
         
       </DialogContent>
      </Dialog>
       {/* Show Event Type End */}


        {/* Show Event Type */}
      <Dialog
      maxWidth="sm"
        fullWidth
        open={classicSquadStatModal}
        keepMounted
        onClose={() => setClssicSquadStatModal(false)}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClssicSquadStatModal(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Squad Statistics</p> <span style={{fontSize:"16px"}}>Squad Type: <span style={{fontSize:"16px",color:"#404040"}}>{squadTitle}</span></span>
        </DialogTitle>
       <DialogContent>
        {
          squadStats && squadStats.length > 0  ? <>
          <div className="eventType_heading">
            <h3>Statistics Name</h3>
          </div>
          {squadStats.map((element,index)=>{
            return <div className="eventtypeDialog_container" key={index}>
              <div>
                <h5>{element}</h5>
              </div>
        </div>
          })
        }
          </> :<p className="event_type_no_data_text">No Squad Statistics Found</p>}
       
       </DialogContent>
      </Dialog>
       {/* Show Event Type End */}


    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
