import React, { useEffect, lazy, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";

import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import {default as CustomInput} from "components/SquadTypeCustomInput/SquadTypeCustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import apiVersionService from "services/apiversion";
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const ImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/ImageUpload.js"
  )
);
const MulImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/MultipleImage.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const event_type =[{
  label:"Match",
  value:1,
},{
  label:"Training",
  value:3,
},{
  label:"Social",
  value:4,
}]
const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const ApiVersionContainer = (props) => {
  const [alert, setAlert] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [activeData, setActiveData] = React.useState("");
  const [master, setMaster] = React.useState({});
  const [leagues, setLeagues] = React.useState([]);
  const [adminList, setAdminList] = React.useState([]);
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const [classicEditModal, setClassicEditModal] = React.useState(false);
  const [current_version, setCurrenVersion] = React.useState("");
  const [minimum_version, setMinimumVersion] = React.useState("");
  const [version_message, setVersionMessage] = React.useState("");
  const [store_url, setStoreUrl] = React.useState("");



  const classes = useStyles();
  useEffect(() => {
    (async () => {
      var apiversion = await apiVersionService.showAllAPIVersion();
      if (apiversion && apiversion.data) {
        setData(
            apiversion.data.map((prop, key) => {
            return {
              id: key+1,
              type:prop.type,
              minimum_version:prop.minimum_version,
              version_message:prop.version_message,
              current_version:prop.current_version,
              store_url:<a href={prop.store_url} target="_blank">{prop.store_url}</a>,
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={key}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={()=>{
                      setClassicEditModal(true)
                      setActiveData(prop.type)
                      setCurrenVersion(prop.current_version)
                      setMinimumVersion(prop.minimum_version)
                      setVersionMessage(prop.version_message)
                      setStoreUrl(prop.store_url)
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Edit />
                  </Button>
                </div>
              ),
            };
          })
        );
      }

      var masterData = await masterService.getMasterData();
      if (masterData && masterData.data) {
        setMaster(masterData.data);
      }
      var leagueData = await masterService.getLeagueList();
      if (leagueData && leagueData.data) {
        setLeagues(leagueData.data);
      }
      var userData = await userService.getUserList();
      if (userData && userData.data) {
        setAdminList(userData.data);
      }
    })();
  }, []);
  const reload = async () => {
    var apiversion = await apiVersionService.showAllAPIVersion();
    if (apiversion && apiversion.data) {
      setData(
          apiversion.data.map((prop, key) => {
          return {
            id: key,
            type:prop.type,
            minimum_version:prop.minimum_version,
            version_message:prop.version_message,
            current_version:prop.current_version,
            store_url:props.store_url,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={key}>
                <Button
                  justIcon
                  round
                  simple
                  color="warning"
                  className="edit"
                >
                  <Edit />
                </Button>
              </div>
            ),
          };
        })
      );
    }

  };
  const OnEditFunction = async (e) => {
    e.preventDefault();
    if(minimum_version == "" || current_version == "" || version_message == "" || store_url == ""){
      props.toastComponentAction.showErrorMessage(
        true,
        "Please fill all the fields"
      );
      return true
    }else{
      const submitData ={
        minimum_version,
        version_message,
        current_version,
        store_url,
       }
      try {
        props.loaderActions.showLoader();
        var resp = await apiVersionService.UpdateApiVersion(activeData,submitData)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload();
        setClassicEditModal(false);
      } catch (err) {
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
    }
  };


  return (
    <>
     {alert}
      <GridContainer
        justify="space-between" // Add it here :)
        container
        >
        <GridItem></GridItem>

      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>API Version List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                     Header:"Id",
                     accessor:"id",
                  },
                  {
                    Header: "Type",
                    accessor: "type",
                  },
                  {
                    Header: "Current Version",
                    accessor: "current_version",
                  },
                  {
                    Header: "Minimum Version",
                    accessor: "minimum_version",
                  },
                  {
                    Header: "Version Message",
                    accessor: "version_message",
                  },
                  {
                    Header: "Store Url",
                    accessor: "store_url",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicEditModal}
        keepMounted
        onClose={() => setClassicEditModal(false)}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            
            onClick={() => setClassicEditModal(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Edit API Version</p>
        </DialogTitle>
        <form onSubmit={OnEditFunction}>
          <DialogContent>
            <CustomInput
              name="current_version"
              inputrefs={register2({ required: true })}
              labelText="Current Version"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              value={current_version}
              onChange={(e) => {
                setCurrenVersion(e.target.value);
              }}
              />
              <CustomInput
              name="minimum_version"
              inputrefs={register2({ required: true })}
              labelText="Minimum Version"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              // focused={true}
              value={minimum_version}
              onChange={(e) => {
                setMinimumVersion(e.target.value);
              }}
              />
              <CustomInput
              name="version_message"
              inputrefs={register2({ required: true })}
              labelText="Version Message"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              // focused={true}
              value={version_message}
              onChange={(e) => {
                setVersionMessage(e.target.value);
              }}
              />
              <CustomInput
              name="store_url"
              inputrefs={register2({ required: true })}
              labelText="Store Url"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              // focused={true}
              value={store_url}
              onChange={(e) => {
                setStoreUrl(e.target.value);
              }}
              />

          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() => {
                setClassicEditModal(false)
              }
              }
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Edit Model End*/}

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiVersionContainer);
