// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getSquadList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/page/all_pages';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const createPage = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/page/create-page';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const editPage = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/page/edit-page';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deletePage = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/page/delete-page';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {

  getSquadList,
  createPage,
  editPage,
  deletePage

};

export default apiService;
