import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOADER_TEXT } from "../../utilities/app";
import './loader.css';

const LoaderComponent = (props) => {
    return (
        <div className="text-center visible relative-loader">
            {props.showLoader &&
                <div className="loading"/>
            }
        </div>
    );
};

LoaderComponent.propTypes = {

}

function mapStateToProps(state) {
    return {
        showLoader: state.loader.showLoader
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoaderComponent);