import { SET_USER_DATA, RESET_USER_DATA, SET_USER_ID } from "./actionTypes";

export default function masterUser(
    state = {
        userId: 0,
        userDetails: {}
    },
    action
) {
    switch (action.type) {

        case SET_USER_DATA:
            return Object.assign({}, state, {
                userDetails: action.userData
            });
        case SET_USER_ID:
            return Object.assign({}, state, {
                userId: action.userId
            });
        case RESET_USER_DATA:
            return {
                ...state,
                userId: 0,
                userDetails: {},

            };
        default:
            return state;
    }
}