import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import masterService from "../../services/apiService";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { DataGrid } from '@material-ui/data-grid';
import ChartistGraph from "react-chartist";
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const SquadContainer = props => {

    const [userStat, setUserStat] = React.useState({});
    const [squadStat, setSquadStat] = React.useState({});
    const [leagueStat, setLeagueStat] = React.useState({});
    const [paymentStat, setPaymentStat] = React.useState({});

    const [table, setTable] = React.useState({});
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            try{

                var data_stats = await masterService.getAnalytics()
                setUserStat({
                    labels: [
                      ...Object.keys(data_stats.data.user_stat)
                    ],
                    series: [
                      [...Object.values(data_stats.data.user_stat)],
                    ]
                  });
                  setSquadStat({
                    labels: [
                      ...Object.keys(data_stats.data.squad_stat)
                    ],
                    series: [
                      [...Object.values(data_stats.data.squad_stat)],
                    ]
                  });
                  setLeagueStat({
                    labels: [
                      ...Object.keys(data_stats.data.league_stat)
                    ],
                    series: [
                      [...Object.values(data_stats.data.league_stat)],
                    ]
                  });
                  setPaymentStat({
                    labels: [
                      ...Object.keys(data_stats.data.payment_stat)
                    ],
                    series: [
                      [...Object.values(data_stats.data.payment_stat)],
                    ]
                  });
                    var cols = Object.keys(data_stats.data.user_stat).map((col, x) => {
                       

                            return { field: col, headerName: col, width: 100, editable: false }
                       
                    })
                    cols.unshift({ field: "", headerName: "", width: 130, editable: false })
                    cols.push({ field: "Total", headerName: "Total", width: 100, editable: false })
                    var obj = {}
                    var rows = [];
                    obj.id = 1
                    Object.values(data_stats.data.user_stat).map((val, i) => {
                      obj[cols[i+1].field] = val
                      
                    })
                    obj[""] = "New Users"
                    obj["Total"] = data_stats.data.total.user_total
                    rows.push(obj);
                    var obj = {}
                    
                    obj.id = 2
                    Object.values(data_stats.data.squad_stat).map((val, i) => {
                      obj[cols[i+1].field] = val
                      
                    })
                    obj[""] = "New Squads"
                    obj["Total"] = data_stats.data.total.squad_total
                    rows.push(obj);
                    var obj = {}
                    
                    obj.id = 3
                    Object.values(data_stats.data.league_stat).map((val, i) => {
                      obj[cols[i+1].field] = val
                      
                    })

                    
                    obj[""] = "New Leagues"
                    obj["Total"] = data_stats.data.total.league_total
                    rows.push(obj);

                    var obj = {}
                    obj.id = 4
                     Object.values(data_stats.data.payment_stat).map((val, i) => {
                      obj[cols[i+1].field] = val
                      
                    })
                    obj[""] = "New Transaction"
                    obj["Total"] = data_stats.data.total.payment_total
                    rows.push(obj);
                    
                    console.log(rows);
                    setTable({cols, rows, title: "users"})
                   
            }catch(err){
                // console.log("err ==>", err.response.data.message)
            }
        })()
    }, [])
   // ##############################
// // // Multiple Bars Chart
// #############################

const multipleBarsChart = {

    options: {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false
      },
      height: "300px"
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }
      ]
    ],
    animation: {
      draw: function(data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease"
            }
          });
        }
      }
    }
  };
  var delays2 = 80, durations2 = 500;
    return (
        <> <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            
                {Object.keys(table).length > 0 ? 
                <div style={{ height: 300, width: '100%', marginBottom:"20px" }}>
               <DataGrid rows={table.rows} columns={table.cols} pageSize={table.rows.length}/>
                </div>
                : null
            }
            </GridItem>
            </GridContainer>
            <GridContainer>
           
            <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
             New Users <small>- Bar Chart</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={userStat ? userStat : {}}
              type="Bar"
              options={multipleBarsChart.options}
              listener={multipleBarsChart.animation}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
             New Squads <small>- Bar Chart</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={squadStat ? squadStat : {}}
              type="Bar"
              options={multipleBarsChart.options}
              listener={multipleBarsChart.animation}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
             New Leagues <small>- Bar Chart</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={leagueStat ? leagueStat : {}}
              type="Bar"
              options={multipleBarsChart.options}
              listener={multipleBarsChart.animation}
            />
          </CardBody>
        </Card>
      </GridItem>


      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
             New Transaction <small>- Bar Chart</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={paymentStat ? paymentStat : {}}
              type="Bar"
              options={multipleBarsChart.options}
              listener={multipleBarsChart.animation}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer></>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
