import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import style from './modal.module.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitleComponent = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});


function ModalComponent(props) {

    return (
        <div>

            <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitleComponent id="customized-dialog-title" onClose={props.onClose}>
                    {props.title}
                </DialogTitleComponent>
                <DialogContent dividers className={style.dialogDivModal}>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={props.handleClick} color="primary">
                        Submit
          </Button> */}

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalComponent;