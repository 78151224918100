
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request";

function getCommonHeaderDetailsWithToken(data) {
    return Object.assign({}, {
      'Content-Type': CONTENT_TYPE,
      'Authorization':  getUserToken()
    }, data || {});
  }

  function getUserToken() {
    let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
    let bearerToken = "";
  
    if (token)
      bearerToken = "Bearer " + token;
  
    return bearerToken;
  }

  
  const paymentList = (data={})=>{
    let url = process.env.REACT_APP_API_HOST + '/admin/payment/list';
    let headerData = {};
    return request(
      url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }

const paymentService={
paymentList
}
export default paymentService;
