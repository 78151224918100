import { combineReducers } from "redux";
import loginReducer from "../containers/Login/reducers";
import LoaderReducer from "./loaderReducer";
import toastComponentReducer from './toast-componentReducer';
import Modalreduce from "./modalreduce";
import changePasswordReducer from "../containers/ChangePassword/reducers";
import masterUserReducer from "./masterUserReducer";
import forgotPasswordReducer from "../containers/ForgotPassword/reducers";

export default combineReducers({
    modal: Modalreduce,
    login: loginReducer,
    loader: LoaderReducer,
    toastComponent: toastComponentReducer,
    forgotPassword: forgotPasswordReducer,
    changePassword: changePasswordReducer,
    masterUser: masterUserReducer
});
