// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}
function getCommonHeaderDetailsWithTokenForApi(data) {
    return Object.assign({}, {
      'Content-Type': CONTENT_TYPE,
      'authorization':  getUserTokenForApi()
    }, data || {});
  }
  function getUserTokenForApi() {
    let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
    let bearerToken = "";
  
    if (token)
      bearerToken = token;
  
    return bearerToken;
  }

const getPostReports = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/report/all_reports_post';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getUserReports = (data = {}) => {
    let url = process.env.REACT_APP_API_HOST + '/admin/report/all_reports_user';
    let headerData = {};
    return request(
      url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }
const getPostDetials = (data = {}, id) => {
    let url = process.env.REACT_APP_API_HOST + '/admin/feed/get-post-details?post_id='+id;
    let headerData = {};
    return request(
      url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }
  const getUserDetails = (data = {}, id) => {
    let url = process.env.REACT_APP_API_HOST + '/admin/get-user-info?user_id='+id;
    let headerData = {};
    return request(
      url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }
  const postStatusChange = (data = {}) => {
    let url = process.env.REACT_APP_API_HOST + '/admin/feed/active_inactive';
    let headerData = {};
    return request(
      url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }
  const deleteReport = (data = {}, id) => {
    let url = process.env.REACT_APP_API_HOST + '/admin/report/delete_report/'+id;
    let headerData = {};
    return request(
      url,  'delete', getCommonHeaderDetailsWithToken(headerData), data,false
  
    )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw (error);
      });
  }
const apiService = {

  getPostReports,
  getPostDetials,
  getUserDetails,
  postStatusChange,
  deleteReport,
  getUserReports

};

export default apiService;
