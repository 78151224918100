export const ACTION_TYPE =
  "ACTION_TYPE";

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SET_OPEN_MODAL = "SET_OPEN_MODAL";
export const SET_CLOSE_MODAL = "SET_CLOSE_MODAL";

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SHOW_DEFAULT_ERROR_MESSAGE = 'SHOW_DEFAULT_ERROR_MESSAGE';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const RESET_LOGIN_DATA = 'RESET_LOGIN_DATA';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';

export const SET_CHANGE_PROFILE_SUCCESS = 'SET_CHANGE_PROFILE_SUCCESS';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const SET_USER_ID = 'SET_USER_ID';



