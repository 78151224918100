import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserProfileComponent from "components/UserProfileComponent/index.js";
// import history from "../../history";
import * as userProfileAction from "./actions.js";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";

const UserProfileContainer = props => {
    // const [isButtonClick, setButtonClick] = useState(false);

    const [formStateForUserInput, setFormStateForUserInput] = useState({
        email: '',
        username: '',
        name: ''
    });

    const [errorStateForUserInput, setErrorStateForUserInput] = useState({
        email: '',
        username: '',
        name: ''
    });



    useEffect(() => {
        if (props.userData && Object.keys(props.userData).length > 0)
            setFormDataFromProps();

    }, [props.userData]);

    function setFormDataFromProps() {
        if (props.userData && Object.keys(props.userData).length > 0) {
            let formData = { ...formStateForUserInput };

            Object.keys(formStateForUserInput).map((data) => {
                formData[data] = props.userData[data] ? props.userData[data] : '';

            });
            setFormStateForUserInput(formData);
        }

    }

    const onHandleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let formData = { ...formStateForUserInput };
        formData[name] = value;
        setFormStateForUserInput(formData);
        validateData(name, value);
    };




    const validateData = (name, value) => {
        let errorObject = errorStateForUserInput;
        switch (name) {

            case "username":
                errorObject.username = "";
                if (isEmpty(value)) {
                    errorObject.username = "User Name required!";
                }
                else if (!isValueValid(value.trim(), ALPHABETS_NUMBER_ONLY)) {
                    errorObject.username = "Please Enter Valid  User Name ";
                }
                break;

            case "name":
                errorObject.name = "";
                if (isEmpty(value)) {
                    errorObject.name = "Name required!";
                }
                else if (!isValueValid(value.trim(), ALPHABETS_ONLY_WITH_SPACE)) {
                    errorObject.name = "Please Enter Valid  Name ";
                }
                break;

            case "email":
                errorObject.email = "";
                if (isEmpty(value)) {
                    errorObject.email = "Email id required!";
                }
                else if (!isValueValid(value.trim(), EMAIL_PATTERN)) {
                    errorObject.email = "Please Enter Valid  Email id";
                }
                break;



        };
        setErrorStateForUserInput(errorObject);
    };


    const isAllFieldsValid = () => {

        let formErrors = errorStateForUserInput;
        let isAllValid = true;
        for (let fieldName in formErrors) {
            validateData(fieldName, formStateForUserInput[fieldName]);

            if (formErrors[fieldName].length > 0) {
                isAllValid = false;
            }
        }
        return isAllValid;
    }



    function onButtonClick() {
        // setButtonClick(true);
        if (isAllFieldsValid()) {
            let data = {};
            Object.keys(formStateForUserInput).forEach((dta) => {
                data[dta] = formStateForUserInput[dta];

            });
            data.user_id = getValue(props, "props.userData.userId");
            data.status = 1;
            props.userProfileAction.updateProfile(data);

        }
    }

    return (
        <>
          
            <UserProfileComponent
                onSubmit={onButtonClick}
                formState={formStateForUserInput}
                errorState={errorStateForUserInput}
                onHandleChange={onHandleChange}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
        userData: state.masterUser.userDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userProfileAction: bindActionCreators(userProfileAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserProfileContainer);
