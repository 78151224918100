import { SET_USER_TOKEN, SET_LOGIN_SUCCESS, RESET_LOGIN_DATA } from "../../redux/actionTypes";

export default function login(
    state = {
        isLoginSucess: false,
        accessToken: ''
    },
    action
) {
    switch (action.type) {
        case SET_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isLoginSucess: action.loginValue
            });
     
        case SET_USER_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.token
            });
        case RESET_LOGIN_DATA:
            return {
                ...state,
                isLoginSucess: false,
                accessToken: ''
            };
        default:
            return state;
    }
}