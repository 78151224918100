import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResetPasswordComponent from "components/ResetPasswordComponent/index.js";
import queryString from 'query-string';
import * as resetPasswordAction from "./actions.js";
import { getValue, PASSWORD_PATTERN, isEmpty, isValueValid } from "utilities/app.js";
import history from "../../history";

const ResetPasswordContainer = props => {
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    const [isButtonClick, setButtonClick] = useState(false);

    const [formStateForUserInput, setFormStateForUserInput] = useState({
        token: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [errorStateForUserInput, setErrorStateForUserInput] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        let key = "token";
        let tokenFromQueryString = parseURl(props.location.search, key);
        console.log("parmss", tokenFromQueryString);

        if (isEmpty(tokenFromQueryString))
            redirectToLogin();
        else {
            let formData = { ...formStateForUserInput };
            formData["token"] = tokenFromQueryString;
            setFormStateForUserInput(formData);
        }


    }, []);

    function parseURl(queryParam, key) {
        const parsUrlParam = queryString.parse(queryParam);
        let parsedUrl = null;
        if (parsUrlParam && Object.keys(parsUrlParam).length > 0) {
            parsedUrl = parsUrlParam[key];
        }
        return parsedUrl;
    }

    function redirectToLogin() {
        history.push("/auth/login-page");
    }


    useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    useEffect(() => {
        if (props.isLoginSuccess) {
            history.push('/');
        }

    }, [props.isLoginSuccess]);

    const onHandleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let formData = { ...formStateForUserInput };
        formData[name] = value;
        setFormStateForUserInput(formData);
        validateData(name, value);
    };




    const validateData = (name, value) => {
        let errorObject = errorStateForUserInput;
        switch (name) {
            case "newPassword":
                errorObject.newPassword = "";
                if (isEmpty(value)) {
                    errorObject.newPassword = "New Password is Required";
                }
                else if(!isValueValid(value.trim(), PASSWORD_PATTERN)) {
                    errorObject.newPassword = "New Password must be minimum eight characters, at least one letter, one number and one special character";
                }
                break;

            case "confirmPassword":
                errorObject.confirmPassword = "";
                if (isEmpty(value)) {
                    errorObject.confirmPassword = "Confirm Password is Required";
                }
                else if (value != formStateForUserInput.newPassword) {
                    errorObject.confirmPassword = "New Password and Confirm Password should be same  ";
                }
                break;

        };
        setErrorStateForUserInput(errorObject);
    };


    const isAllFieldsValid = () => {

        let formErrors = errorStateForUserInput;
        let isAllValid = true;
        for (let fieldName in formErrors) {
            validateData(fieldName, formStateForUserInput[fieldName]);

            if (formErrors[fieldName].length > 0) {
                isAllValid = false;
            }
        }
        return isAllValid;
    }



    function onButtonClick() {
        setButtonClick(true);
        if (isAllFieldsValid()) {
            let data = {};
            data.token = formStateForUserInput.token;
            data.new_password = formStateForUserInput.newPassword;
            data.confirm_password = formStateForUserInput.confirmPassword;

            props.resetPasswordAction.doResetPassword(data);

        }
    }

    return (
        <>
            <ResetPasswordComponent
                onSubmit={onButtonClick}
                cardAnimaton={cardAnimaton}
                onHandleChange={onHandleChange}
                formState={formStateForUserInput}
                errorState={errorStateForUserInput}
                isButtonClick={isButtonClick}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
        isLoginSuccess: state.login.isLoginSucess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetPasswordAction: bindActionCreators(resetPasswordAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
