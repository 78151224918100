import apiService from "../services/apiService";
import { SET_USER_DATA, RESET_USER_DATA,SET_USER_ID } from "./actionTypes";
import * as loaderActions from "./loaderAction";
import * as toastComponentAction from "./toast-componentAction";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE_ON_LOGIN, getValue, TOAST_MESSAGE_TIMEOUT } from "../utilities/app";


export function resetUserData() {
    return {
        type: RESET_USER_DATA
    }
}

export function setUserId(userId) {
    return {
        type: SET_USER_ID,
        userId
    }
}


export function setUserData(userData) {
    return {
        type: SET_USER_DATA,
        userData
    }
}

export const getUserProfileData = () => {
    return (dispatch) => {
        // 1. Calling showLoader() method to display loader...
        dispatch(loaderActions.showLoader());
        let data = {};
        return apiService.getUserProfile(data)
            .then(result => {
                dispatch(loaderActions.hideLoader());
                if (result.status) {

                    let usrData = {};
                    usrData.userId = getValue(result, "result.data.id");
                    usrData.email = getValue(result, "result.data.email");
                    usrData.username = getValue(result, "result.data.username");
                    usrData.name = getValue(result, "result.data.name");
                    // usrData.contact_number = getValue(result, "result.data.contact_number");

                    dispatch(toastComponentAction.showSuccessMessage(true, result.message));
                    // setTimeout(() => {
                    //     dispatch(toastComponentAction.showSuccessMessage(false, ""));
                    // }, TOAST_MESSAGE_TIMEOUT);
                    dispatch(setUserData(usrData));

                } else {
                    dispatch(toastComponentAction.showErrorMessage(true, result.message));
                    setTimeout(() => {
                        dispatch(toastComponentAction.showErrorMessage(false, ""));
                    }, TOAST_MESSAGE_TIMEOUT);

                }
            }, error => {
                dispatch(loaderActions.hideLoader());
                dispatch(toastComponentAction.showErrorMessage(true, DEFAULT_ERROR_MESSAGE));
                setTimeout(() => {
                    dispatch(toastComponentAction.showErrorMessage(false, ""));
                }, TOAST_MESSAGE_TIMEOUT);

            });
    }
};