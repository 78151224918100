import {
  
    SET_OPEN_MODAL,
    SET_CLOSE_MODAL
  } from "./actionTypes";
  
  export default function Modalreduce(
    state = {
       open : false
      
  
  
    },
    action
  ) {
    switch (action.type) {
      case SET_OPEN_MODAL:
        return Object.assign({}, state, {
            open: action.data
        });
      case SET_CLOSE_MODAL:
        return Object.assign({}, state, {
            open: action.data
        });
        
    
  
   
     
      default:
        return state;
    }
  }