// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}





const showAllAPIVersion = (data)=>{
  let url = process.env.REACT_APP_API_HOST + '/api/v2/apiversion/list';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}


const UpdateApiVersion = (id,data)=>{
  let url = process.env.REACT_APP_API_HOST + `/api/v2/apiversion/update/${id}`;
  let headerData = {};
  return request(
    url,  'PUT', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const apiVersionService = {
    showAllAPIVersion,
    UpdateApiVersion
};

export default apiVersionService;
