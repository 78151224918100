// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";
const axios = require('axios');
//const Config = require('Config');


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
const login = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/login';
  let headerData = {};
  return request(
    url,
    'post', getCommonHeaderDetails(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const forgotPassword = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/forgot-password';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetails(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const resetPassword = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/reset_password';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetails(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const changePassword = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/change_password';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const getUserProfile = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/get-profile';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const updateUserProfile = (data) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/edit_user';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const getMasterData = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/api/v2/get-master-data';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editSport = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/sports/edit_sport';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const addPosition = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/position/add_position';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const addColour = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/colour/add_colour';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editColour = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/colour/edit_colour';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editPosition = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/position/edit_positions';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getAnalytics = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/stat/analytics';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getLeagueList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/api/v2/get-league-list';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}


const apiService = {

  login,
  forgotPassword,
  resetPassword,
  changePassword,
  getUserProfile,
  updateUserProfile,
  getMasterData,
  getLeagueList,
  getAnalytics,
  editSport,
  addPosition,
  editPosition,
  addColour,
  editColour

};

export default apiService;
