/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, Suspense } from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import store from "redux/store.js";
import LoaderComponent from "components/Loader/loader.js";
import ToastComponent from "components/toast-component.js";
import hist from "history.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

// const hist = createBrowserHistory();

ReactDOM.render(
  <Fragment>
    <Provider store={store()}>
      <Suspense fallback={<div>Loading</div>}>
        <LoaderComponent />
        <ToastComponent />
        <Router history={hist}>
          <Switch>
            <Route path="/rtl" component={RtlLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/admin" component={AdminLayout} />
            <Redirect from="/" to="/admin/users" />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  </Fragment>
  ,
  document.getElementById("root")
);
