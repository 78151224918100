// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getSquadList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/all_squads';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const createSquad = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/create_squad';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const editSquad = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/edit_squad/'+id;
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editSquadStat = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/stat/edit-squad-stats';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deleteSquad = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/delete-squad';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), {squad_id: id},false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const CreateSquadType = (data)=>{
  let url = process.env.REACT_APP_API_HOST + '/api/v2/squad_type/create_squadtype';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const ShowAllSport = (data)=>{
  let url = process.env.REACT_APP_API_HOST + '/api/v2/squad_type';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const DeleteSquadType = (data)=>{
  let url = process.env.REACT_APP_API_HOST + '/api/v2/squad_type/delete_squad_type';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const UpdateSquadType = (id,data)=>{
  let url = process.env.REACT_APP_API_HOST + `/api/v2/squad_type/update_squadtype/${id}`;
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const apiService = {

  getSquadList,
  createSquad,
  editSquad,
  deleteSquad,
  editSquadStat,
  CreateSquadType,
  ShowAllSport,
  DeleteSquadType,
  UpdateSquadType

};

export default apiService;
