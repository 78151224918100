import * as actionTypes from './actionTypes';
import { TOAST_MESSAGE_TIMEOUT } from "../utilities/app";

function setSuccessMessage(value, message) {
  return {
    type: actionTypes.SET_SUCCESS_MESSAGE,
    'isSuccessMessage': value,
    'message': message,
    'isErrorMessage': false,
    'isWarningMessage':false
  }
}

export function showSuccessMessage(value, message) {
  console.log("values ==>", value, message);
  return (dispatch) => {
    dispatch(setSuccessMessage(value, message));
    setTimeout(() => {
      dispatch(setSuccessMessage(false, ''));
    }, TOAST_MESSAGE_TIMEOUT);
  };
}

export function setErrorMessage(value, message) {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    'isErrorMessage': value,
    'message': message,
    'isSuccessMessage': false,
    'isWarningMessage':false
  }
}

export function showErrorMessage(value, message) {
  return (dispatch) => {
    dispatch(setErrorMessage(value, message));
    setTimeout(() => {
      dispatch(setErrorMessage(false, ''));
    }, TOAST_MESSAGE_TIMEOUT);
  };
}

export function setWarningMessage(value, message) {
    return {
      type: actionTypes.SET_WARNING_MESSAGE,
      'isWarningMessage': value,
      'message': message,
      'isSuccessMessage': false,
      'isErrorMessage': false,
    }
  }
  
  export function showWarningMessage(value, message) {
    return (dispatch) => {
      dispatch(setWarningMessage(value, message));
      setTimeout(() => {
        dispatch(setWarningMessage(false, ''));
      }, TOAST_MESSAGE_TIMEOUT);
    };
  }

export function showDefaultErrorMessage(value) {
    return {
      type: actionTypes.SHOW_DEFAULT_ERROR_MESSAGE,
      'isDefaultErrorMessage': value,
      'isSuccessMessage': false
    }
  }



