import _ from "lodash";

export const ALPHABETS_ONLY = "^[A-Za-z]+$";
export const ALPHABETS_ONLY_WITH_SPACE = "^([A-Za-z]+ ?)*$"; //"whitespace"?  - allow whitespace after word, set is as optional
export const ALPHABETS_NUMBER_ONLY = "^[a-zA-Z0-9_.-]*$";
export const ALPHABETS_NUMBER_ONLY_WITH_SPACE = "^([a-zA-Z0-9_.-]+ ?)*$";
export const ALPHABETS_NUMBER_SPECIAL_CHARACTERS = "^[ A-Za-z0-9_@#&()%$]*$";
export const ALPHABETS_NUMBER_SPECIAL_CHARACTERS_WITH_SPACE = "^([ A-Za-z0-9_@#&()%$]+ ?)*$";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong, Please try again later";
export const PAGE_SIZE = 20;
export const MY_ACTIVITY_PAGE_SIZE = 5;
export const COMMENT_PAGE_SIZE = 35;
export const EMAIL_PATTERN =
  "^[A-Za-z0-9._%-+]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$";
export const PASSWORD_PATTERN = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const ALPHABET_PATTERN_WITH_SPECIAL_CHARACTERS = "^[a-zA-Z '-]*$";
export const NUMBER_PATTERN = "^[0-9]*$";
export const LOADER_TEXT = "In Progress";
export const TOAST_MESSAGE_TIMEOUT = "5000";
export const MESSAGE_TIMEOUT = 5;
export const DEFAULT_SUCCESS_MESSAGE_ON_ADD = "Record Added Sucessfully";
export const DEFAULT_SUCCESS_MESSAGE_ON_GET = "Record Fetched Sucessfully";
export const DEFAULT_SUCCESS_MESSAGE_ON_UPDATE = "Record Updated Sucessfully";
export const DEFAULT_SUCCESS_MESSAGE_ON_DELETE = "Record Deleted Sucessfully";
export const DEFAULT_SUCCESS_MESSAGE_ON_LOGIN = "Login successfully done";
export const CONTENT_TYPE = "application/json";



export const valueExist = (obj, expression) => {
  try {
    expression = "obj." + expression.substring(expression.indexOf(".") + 1);
    return !!eval(expression);
  } catch (e) {
    return false;
  }
};

export const getValue = (obj, expression) => {
  try {
    return expression.split(".").reduce((o, x, i) => {
      if (i === 0) {
        return o;
      }
      return typeof o === "undefined" || o === null ? undefined : o[x];
    }, obj);
  } catch (e) {
    console.error("getValue => " + e);
    return undefined;
  }
};





export const isEmpty = value => {
  return (
    !value ||
    value === null ||
    (typeof value === "string" && value.trim() === "")
  );
};

export const isNumeric = value => {
  if (!value) return true;
  const reg = new RegExp("^[0-9]+$");
  return reg.test(value);
};

export const isFileTypeDoc = file => {
  const fileName = file.name;
  const idxDot = fileName.lastIndexOf(".") + 1;
  const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  return (
    extFile === "jpg" ||
    extFile === "jpeg" ||
    extFile === "docx" ||
    extFile === "doc" ||
    extFile === "pdf" ||
    extFile === "xlsx" ||
    extFile === "xls" ||
    extFile === "pptx" ||
    extFile === "ppt"
  );
};

export const isFileTypeAcceptedForComment = file => {
  const fileName = file.name;
  const idxDot = fileName.lastIndexOf(".") + 1;
  const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  return (
    extFile === "jpg" ||
    extFile === "jpeg" ||
    extFile === "docx" ||
    extFile === "doc" ||
    extFile === "pdf" ||
    extFile === "xlsx" ||
    extFile === "xls"
  );
};

export const isFileTypeImage = file => {
  const fileName = file.name;
  const idxDot = fileName.lastIndexOf(".") + 1;
  const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  return (
    extFile === "jpg" ||
    extFile === "jpeg" ||
    extFile === "png" ||
    extFile === "svg"
  );
};

export const isValueValid = (value, regExp) => {
  const reg = new RegExp(regExp);
  return reg.test(value);
};

export const isValueMatch = (value, matchValue) => {
  return value == matchValue;
};
export const isLessThanMinLength = (value, length) => {
  return value.length <= length;
};

export const isGreaterThanMaxLength = (value, length) => {
  return value.length >= length;
};

export const isFileSizeExceed = (file, maxSize) => {
  const fileSize = file.size; // in bytes
  return fileSize > maxSize; // *0.000001 means converted to mb
};

export const isInLengthRange = (value, minLength, maxLength) => {
  return value.length >= minLength && value.length <= maxLength;
};

export const getBase64String = (file, cb) => {
  let reader = new FileReader();
  let data;
  reader.readAsDataURL(file);
  reader.onloadend = function() {
    data = reader.result.split(";");
    cb(data[1].split(",")[1]);
  };
  reader.onerror = function(error) {
    console.log("Error: ", error);
  };
};

export const isValidEmail = email => {
  return isValueValid(email, EMAIL_PATTERN);
};


export const MAX_TRIES_POLLING  = 5;
export const  NOTIFICATION_COUNT_READ_STATUS = "SUCCESS";
export const  NOTIFICATION_COUNT_STATUS = "PENDING";
export const NOTIFICATION_COUNT_READ_RECIPIENTS_status = "SUCCESS";
