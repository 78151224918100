// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".change-password_submitButton__31MXZ {\n  display: flex !important;\n  flex-direction: row !important;\n  align-items: center !important;\n  padding: 9px 37px !important;\n  margin: 0 auto !important;\n  width: 125px !important;\n  height: 42px !important; }\n", ""]);
// Exports
exports.locals = {
	"submitButton": "change-password_submitButton__31MXZ"
};
module.exports = exports;
