// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getUserList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/all_user_options';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}


const getUserDetails = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/get-user-stats?user_id='+data.user_id;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const createUser = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/create-user';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editUser = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/edit_user';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const changeVerified = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/change_verified';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const changeUserStatus = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/active_inactive_user';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {

  getUserList,
  getUserDetails,
  changeVerified,
  changeUserStatus,
  createUser,
  editUser

};

export default apiService;
