// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal_dialogDivModal__3U5__ {\n  width: 550px;\n  height: auto;\n  border: none; }\n", ""]);
// Exports
exports.locals = {
	"dialogDivModal": "modal_dialogDivModal__3U5__"
};
module.exports = exports;
