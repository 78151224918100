import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/squad";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { findLast } from "lodash";
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const SquadContainer = props => {

    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [master, setMaster] = React.useState({});
    const [leagues, setLeagues] = React.useState([]);
    const [adminList, setAdminList] = React.useState([]);
    const [selectedAdminList, setSelectedAdminList] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState("");
    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedLeague, setSelectedLeague] = React.useState("");
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalAdd, setClassicModalAdd] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            var squads = await masterService.getMasterData()
            console.log("resp ==>", squads);
            if (squads && squads.data) {
                setData(squads.data.positions.map((prop, key) => {
                    console.log("prop ==>", prop);
                    return {
                        id: key,
                        name: prop.label,
                        sport: prop.sport_name,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        reset({
                                            name: prop.label
                                        })
                                        console.log("props in edit ==>", prop);
                                        setActiveData({ ...prop });
                                        // setSelectedColor(prop.color_id);
                                         setSelectedSport(prop.sport_id);
                                        // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                        // setSelectedAdminList(prop.admin_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }

            var masterData = await masterService.getMasterData()
            console.log("masterData ==>", masterData);
            if (masterData && masterData.data) {
                setMaster(masterData.data)
            }
            var leagueData = await masterService.getLeagueList()
            console.log("leagueData ==>", leagueData);
            if (leagueData && leagueData.data) {
                setLeagues(leagueData.data)
            }
            var userData = await userService.getUserList()
            console.log("userData ==>", userData);
            if (userData && userData.data) {
                setAdminList(userData.data)
            }
        })()
    }, [])
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async () => {
        var squads = await masterService.getMasterData()
        console.log("resp ==>", squads);
        if (squads && squads.data) {
            setData(squads.data.positions.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    id: key,
                    name: prop.label,
                    sport: prop.sport_name,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    reset({
                                        name: prop.label
                                    })
                                    console.log("props in edit ==>", prop);
                                    setActiveData({ ...prop });
                                    // setSelectedColor(prop.color_id);
                                     setSelectedSport(prop.sport_id);
                                    // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                    // setSelectedAdminList(prop.admin_ids.split(","))
                                    setClassicModal(true)
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }
    const onSubmit = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else {
            var final = {};
            final.position_id = activeData.value
            final.title = data.name
            final.sport_id = selectedSport
            console.log(final)
            // final.append("title", data.name);
            // final.append("sport_id", selectedSport);
            try {
                props.loaderActions.showLoader();
                var resp = await masterService.editPosition(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModal(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const onSubmitAdd = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else {
            var final = {};
            final.title = data.name
            final.sport_id = selectedSport
            // final.append("title", data.name);
            // final.append("sport_id", selectedSport);
            try {
                props.loaderActions.showLoader();
                var resp = await masterService.addPosition(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModalAdd(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    return (
        <>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setSelectedColor("");
                            setSelectedSport("");
                            setSelectedLeague("");
                            setSelectedAdminList([])
                            reset2()
                            setClassicModalAdd(true)
                        }}>
                            Add Position
              </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Player Position List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Name",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Sport",
                                        accessor: "sport"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Edit Position</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                    <FormControl fullWidth className={classes.formControl}>

                    <InputLabel>Select Sport</InputLabel>
                    <Select
                        required
                        MenuProps={{
                            className: classes.selectMenu
                        }}
                        classes={{
                            select: classes.select
                        }}

                        value={selectedSport}
                        onChange={(e) => {
                            setSelectedSport(e.target.value)
                        }}
                        inputProps={{
                            name: "simpleSelect"
                        }}

                    >
                        {master.sports && master.sports.length > 0 ?
                            master.sports.map(art => {
                                return <MenuItem
                                    key={art.value}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value={art.value}
                                >
                                    {art.label}
                                </MenuItem>
                            })
                            : null}
                    </Select>
                    </FormControl>
                    <CustomInput
                    name="name"
                    inputrefs={register({ required: true })}
                    labelText="Name"
                    id="name2"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        autoComplete: "off",
                        defaultValue: activeData.label
                    }}
                    />
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
     </Button>
                    </DialogActions>
                </form> : null}

            </Dialog>
            {/*Edit Model End*/}
            {/*Add Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAdd}
                keepMounted
                onClose={() => setClassicModalAdd(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAdd(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Add Player Position</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(onSubmitAdd)}>
                    <DialogContent>
                       
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <CustomInput
                            name="name"
                            inputrefs={register2({ required: true })}
                            labelText="Name"
                            id="name2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                       
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAdd(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
