import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function LoginComponent(props) {

    function handleChngeEvent(event) {
        console.log('event: ', event);
        props.onHandleChange(event);
    }

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <Card login className={classes[props.cardAnimaton]}>
                            {/* <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader> */}
                            <CardBody>
                                <center>
                                    <h4 > {props.isFromLoginPage ? "Log In" : "Forgot Password"}</h4>
                                </center>

                                <CustomInput
                                    labelText="Email..."
                                    id="email"

                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>

                                        ),
                                        name: "email",
                                        value: props.formState.email,
                                        onChange: (event) => { 
                                            console.log('event: ', event);
                                            handleChngeEvent(event) 
                                        }
                                    }
                                    }
                                />
                                {
                                    props.isButtonClick &&
                                    <Danger> {props.errorState.email} </Danger>
                                }
                                {
                                    props.isFromLoginPage &&
                                    <>
                                        <CustomInput
                                            labelText="Password"
                                            id="password"


                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon className={classes.inputAdornmentIcon}>
                                                            lock_outline
                            </Icon>
                                                    </InputAdornment>
                                                ),
                                                type: "password",
                                                autoComplete: "off",
                                                name: "password",
                                                value: props.formState.password,
                                                onChange: (event) => { handleChngeEvent(event) }
                                            }}
                                        />
                                        {
                                            props.isButtonClick &&
                                            <Danger> {props.errorState.password} </Danger>
                                        }
                                        <Link style={{ marginLeft: "30%" }} className="login-form-forgot" to="/auth/forgot-password-page">
                                            Forgot password ?
                                        </Link>
                                    </>
                                }
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" onClick={props.onSubmit}>
                                    Submit
                    </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );

};

export default LoginComponent;

