import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/league";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { DataGrid } from '@material-ui/data-grid';
import { findLast } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const SquadContainer = props => {
    const [alert, setAlert] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [master, setMaster] = React.useState({});
    const [squads, setSquads] = React.useState([]);
    const [adminList, setAdminList] = React.useState([]);
    const [selectedAdminList, setSelectedAdminList] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState("");
    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedSquads, setSelectedSquads] = React.useState([]);
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const { register: register3, handleSubmit: handleSubmit3 } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalAdd, setClassicModalAdd] = React.useState(false);
    const [classicModalAddFixture, setClassicModalAddFixture] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const [table, setTable] = React.useState([])
    const classes = useStyles();
    const classesalert = useStylesAlert();
    useEffect(() => {
        (async () => {
            var squads = await squadService.getLeagueList()
            console.log("resp ==>", data);
            if (squads && squads.data) {
                setData(squads.data.map((prop, key) => {
                    console.log("prop ==>", prop);
                    return {
                        id: key,
                        name: prop.name,
                        idcode: prop.id,
                        squad_cnt: prop.squad_cnt,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={async () => {
                                        var details = await squadService.getLeagueDetails({}, prop.id)
                                        console.log("details ==>", details);
                                        var tables = []
                                        if (details.data.table !== "[]") {

                                            var league_table = JSON.parse(details.data.table);
                                            console.log("league table ==>", league_table);
                                            var cols = league_table.columns.map((col, k) => {
                                                return { field: k, headerName: col, width: 150, editable: k > 1 ? true : false }
                                            })
                                            var rows = league_table.values.map((val, j) => {
                                                var obj = {}
                                                obj.id = j;
                                                val.stats.map((stat, i) => {
                                                    obj[cols[i].field] = stat
                                                })
                                                return obj;
                                            })
                                            console.log("rows ==>", rows);

                                            tables.push({ cols, rows })
                                        } else {
                                            tables.push({ cols: [], rows: [] })
                                        }
                                        if (details.data.results !== "[]") {

                                            var league_results = JSON.parse(details.data.results);
                                            console.log("league results ==>", league_results);
                                            var cols2 = [
                                                { field: "date", headerName: "Date", width: 150, editable: false },
                                                { field: "home", headerName: "Home", width: 150, editable: false },
                                                { field: "home_score", headerName: "Home Score", width: 150, editable: true },
                                                { field: "away", headerName: "Away", width: 150, editable: false },
                                                { field: "away_score", headerName: "Away Score", width: 150, editable: true },
                                                { field: "result", headerName: "Result", width: 150, editable: false }
                                            ]
                                            var cnt = 0
                                            var rows2 = league_results.map((date, j) => {

                                                return date.matches.map((match, i) => {
                                                    var obj = {}
                                                    obj.id = cnt++;
                                                    obj.date = date.date;
                                                    obj.home = match.home_team_name
                                                    obj.away = match.away_team_name
                                                    obj.home_score = match.home_team_score
                                                    obj.away_score = match.away_team_score
                                                    obj.result = "Done"
                                                    return obj
                                                })
                                            })
                                            console.log("rows2 ==>", rows2);
                                            rows2 = rows2.flat();
                                            console.log("rows2 flatten ==>", rows2);
                                            tables.push({ cols2, rows2 });
                                        } else {
                                            tables.push({ cols2: [], rows2: [] })
                                        }
                                        if (details.data.fixtures !== "[]") {
                                            var league_fixtures = JSON.parse(details.data.fixtures);
                                            console.log("league fixtures ==>", league_fixtures);
                                            var cols3 = [
                                                { field: "date", headerName: "Date", width: 150, editable: false },
                                                { field: "home", headerName: "Home", width: 150, editable: false },
                                                { field: "away", headerName: "Away", width: 150, editable: false }
                                            ]
                                            var cnt = 0
                                            var rows3 = league_fixtures.map((date, j) => {

                                                return date.matches.map((match, i) => {
                                                    console.log("match ==>", match);
                                                    var obj = {}
                                                    obj.id = cnt++;
                                                    obj.date = date.date;
                                                    obj.home = match.home_team_name
                                                    obj.away = match.away_team_name
                                                    return obj
                                                })
                                            })
                                            console.log("rows3 ==>", rows3);
                                            rows3 = rows3.flat();
                                            console.log("rows3 flatten ==>", rows3);
                                            tables.push({ cols3, rows3 });
                                        } else {
                                            tables.push({ cols3: [], rows3: [] })
                                        }
                                        if (details.data.stats !== "[]") {
                                            var league_stats = JSON.parse(details.data.stats);
                                            console.log("league stats ==>", league_stats);
                                            var cols4 = league_stats.columns.map((col, k) => {
                                                return { field: k, headerName: col, width: 150, editable: k > 2 ? true : false }
                                            })
                                            var rows4 = league_stats.values.map((val, j) => {
                                                var obj = {}
                                                obj.id = j;
                                                val.stats.map((stat, i) => {
                                                    obj[cols4[i].field] = stat
                                                })
                                                return obj;
                                            })
                                            console.log("rows4 ==>", rows4);
                                            tables.push({ cols4, rows4 })
                                        } else {
                                            tables.push({ cols4: [], rows4: [] })
                                        }
                                        setTable([...tables])
                                        // reset({
                                        //     name: prop.name,
                                        //     profile_rank: prop.profile_rank
                                        // })
                                        // console.log("props in edit ==>", prop);
                                        setActiveData({ ...prop });
                                        reset({
                                            name: prop.name,
                                            profile_rank: prop.profile_rank
                                        })
                                        setSelectedSport(prop.sport_id);
                                        // setSelectedSport(prop.sport_id);
                                        // setSelectedSquads(prop.squad_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => warningWithConfirmAndCancelMessage({id: prop.id})}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }

            var masterData = await masterService.getMasterData()
            console.log("masterData ==>", masterData);
            if (masterData && masterData.data) {
                setMaster(masterData.data)
            }
            var squadData = await squadService.getSquadList()
            console.log("squadData ==>", squadData);
            if (squadData && squadData.data) {
                setSquads(squadData.data)
            }
            var userData = await userService.getUserList()
            console.log("userData ==>", userData);
            if (userData && userData.data) {
                setAdminList(userData.data)
            }
        })()
    }, [])
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async () => {
        var squads = await squadService.getLeagueList()
        console.log("resp ==>", data);
        if (squads && squads.data) {
            setData(squads.data.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    id: key,
                    name: prop.name,
                    idcode: prop.id,
                    squad_cnt: prop.squad_cnt,
                    actions: (
                       // we've added some custom button actions
                       <div className="actions-right">
                       <Button
                           justIcon
                           round
                           simple
                           onClick={async () => {
                               var details = await squadService.getLeagueDetails({}, prop.id)
                               console.log("details ==>", details);
                               var tables = []
                               if (details.data.table !== "[]") {

                                   var league_table = JSON.parse(details.data.table);
                                   console.log("league table ==>", league_table);
                                   var cols = league_table.columns.map((col, k) => {
                                       return { field: k, headerName: col, width: 150, editable: k > 1 ? true : false }
                                   })
                                   var rows = league_table.values.map((val, j) => {
                                       var obj = {}
                                       obj.id = j;
                                       val.stats.map((stat, i) => {
                                           obj[cols[i].field] = stat
                                       })
                                       return obj;
                                   })
                                   console.log("rows ==>", rows);

                                   tables.push({ cols, rows })
                               } else {
                                   tables.push({ cols: [], rows: [] })
                               }
                               if (details.data.results !== "[]") {

                                   var league_results = JSON.parse(details.data.results);
                                   console.log("league results ==>", league_results);
                                   var cols2 = [
                                       { field: "date", headerName: "Date", width: 150, editable: false },
                                       { field: "home", headerName: "Home", width: 150, editable: false },
                                       { field: "home_score", headerName: "Home Score", width: 150, editable: true },
                                       { field: "away", headerName: "Away", width: 150, editable: false },
                                       { field: "away_score", headerName: "Away Score", width: 150, editable: true },
                                       { field: "result", headerName: "Result", width: 150, editable: false }
                                   ]
                                   var cnt = 0
                                   var rows2 = league_results.map((date, j) => {

                                       return date.matches.map((match, i) => {
                                           var obj = {}
                                           obj.id = cnt++;
                                           obj.date = date.date;
                                           obj.home = match.home_team_name
                                           obj.away = match.away_team_name
                                           obj.home_score = match.home_team_score
                                           obj.away_score = match.away_team_score
                                           obj.result = "Done"
                                           return obj
                                       })
                                   })
                                   console.log("rows2 ==>", rows2);
                                   rows2 = rows2.flat();
                                   console.log("rows2 flatten ==>", rows2);
                                   tables.push({ cols2, rows2 });
                               } else {
                                   tables.push({ cols2: [], rows2: [] })
                               }
                               if (details.data.fixtures !== "[]") {
                                   var league_fixtures = JSON.parse(details.data.fixtures);
                                   console.log("league fixtures ==>", league_fixtures);
                                   var cols3 = [
                                       { field: "date", headerName: "Date", width: 150, editable: false },
                                       { field: "home", headerName: "Home", width: 150, editable: false },
                                       { field: "away", headerName: "Away", width: 150, editable: false }
                                   ]
                                   var cnt = 0
                                   var rows3 = league_fixtures.map((date, j) => {

                                       return date.matches.map((match, i) => {
                                           console.log("match ==>", match);
                                           var obj = {}
                                           obj.id = cnt++;
                                           obj.date = date.date;
                                           obj.home = match.home_team_name
                                           obj.away = match.away_team_name
                                           return obj
                                       })
                                   })
                                   console.log("rows3 ==>", rows3);
                                   rows3 = rows3.flat();
                                   console.log("rows3 flatten ==>", rows3);
                                   tables.push({ cols3, rows3 });
                               } else {
                                   tables.push({ cols3: [], rows3: [] })
                               }
                               if (details.data.stats !== "[]") {
                                   var league_stats = JSON.parse(details.data.stats);
                                   console.log("league stats ==>", league_stats);
                                   var cols4 = league_stats.columns.map((col, k) => {
                                       return { field: k, headerName: col, width: 150, editable: k > 2 ? true : false }
                                   })
                                   var rows4 = league_stats.values.map((val, j) => {
                                       var obj = {}
                                       obj.id = j;
                                       val.stats.map((stat, i) => {
                                           obj[cols4[i].field] = stat
                                       })
                                       return obj;
                                   })
                                   console.log("rows4 ==>", rows4);
                                   tables.push({ cols4, rows4 })
                               } else {
                                   tables.push({ cols4: [], rows4: [] })
                               }
                               setTable([...tables])
                               // reset({
                               //     name: prop.name,
                               //     profile_rank: prop.profile_rank
                               // })
                               // console.log("props in edit ==>", prop);
                               setActiveData({ ...prop });
                               reset({
                                   name: prop.name,
                                   profile_rank: prop.profile_rank
                               })
                               setSelectedSport(prop.sport_id);
                               // setSelectedSport(prop.sport_id);
                               // setSelectedSquads(prop.squad_ids.split(","))
                               setClassicModal(true)
                           }}
                           color="warning"
                           className="edit"
                       >
                           <Edit />
                       </Button>{" "}
                       <Button
                           justIcon
                           round
                           simple
                           onClick={() => warningWithConfirmAndCancelMessage({id: prop.id})}
                           color="danger"
                           className="remove"
                       >
                           <Close />
                       </Button>{" "}
                   </div>
                    )
                };
            }));
        }
    }
    const onSubmit = async (data) => {
        // setClassicModal(false)
        console.log("submit data ==>", data, selectedSport);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else {
            var final = {};
            final.name = data.name;
            final.profile_rank = data.profile_rank;
            final.sport_id = selectedSport
            // final.append("name", data.name);
            // final.append("profile_rank", data.profile_rank);
            // final.append("sport_id", selectedSport);
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.editLeague(final, activeData.id);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModal(false)
            } catch (err) {
                console.log("err ==>", err);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const onSubmitAdd = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else {
            var final = {}
            final.name = data.name
            final.profile_rank = data.profile_rank
            final.sport_id = selectedSport
            final.squad_cnt = 0;
            final.squad_ids = "";
            // if (profile_pic) {
            //     final.append("squad_badge", profile_pic)
            // }
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.createLeague(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModalAdd(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const onSubmitAddFixture = async (data) => {
        console.log("submit data ==>", data);

        var final = {}
        final.event_date = data.date
        final.home_team_name = data.home_name
        final.away_team_name = data.away_name
        final.league_id = activeData.id
        // if (profile_pic) {
        //     final.append("squad_badge", profile_pic)
        // }
        try {
            props.loaderActions.showLoader();
            var resp = await squadService.addFixture(final);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            reload();
            setClassicModalAddFixture(false)
        } catch (err) {
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
        console.log("create resp ==>", resp);

    }
    const onEditCellStat = async (data) => {
        console.log("cell change ==>", data);
        var obj = {}
        obj.league_id = activeData.id
        obj.stats = JSON.stringify({ field: data.field, value: data.props.value, row: data.id })
        try {
            props.loaderActions.showLoader();
            var resp = await squadService.editLeagueStats(obj);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            console.log("resp ==>", resp);
        } catch (err) {
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
    }
    const onEditCell = async (data) => {
        console.log("cell change ==>", data);
        var obj = {}
        obj.league_id = activeData.id
        obj.stats = JSON.stringify({ field: data.field, value: data.props.value, row: data.id })
        try {
            props.loaderActions.showLoader();
            var resp = await squadService.editLeagueTable(obj);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            console.log("resp ==>", resp);
        } catch (err) {
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
    }
    const warningWithConfirmAndCancelMessage = (data) => {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => successDelete(data)}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classesalert.button + " " + classesalert.success}
            cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            You will not be able to revert this!
          </SweetAlert>
        );
      };
      const successDelete = async (data) => {
        props.loaderActions.showLoader();
        var resp = await squadService.deleteLeague({}, data.id)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload();
        hideAlert();
      };
      const hideAlert = () => {
        setAlert(null);
      };
    return (
        <>
        {alert}
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setSelectedSport("");
                            setSelectedSquads([])
                            setClassicModalAdd(true)
                            reset2()
                        }}>
                            Add League
              </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>League List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "ID Code",
                                        accessor: "idcode"
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Total Squad",
                                        accessor: "squad_cnt"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>League Details</p>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <CustomInput
                            name="name"
                            inputrefs={register({ required: true })}
                            labelText="Name"
                            id="name2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.name}`
                            }}
                        />
                        <CustomInput
                            name="profile_rank"
                            inputrefs={register({ required: true })}
                            labelText="Verified Step"
                            id="profile_rank"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: 0
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl><br /><br />
                        {
                            table.length > 0 ?
                                <CustomTabs
                                    title=""
                                    headerColor="rose"
                                    tabs={[
                                        {
                                            tabName: "Table",
                                            tabIcon: Assignment,
                                            tabContent: <div style={{ height: 300, width: '100%', marginBottom: "20px" }}>
                                                <DataGrid rows={table[0].rows} columns={table[0].cols} pageSize={table[0].rows.length} onEditCellChangeCommitted={onEditCell} />
                                            </div>
                                        },
                                        {
                                            tabName: "Results",
                                            tabIcon: Assignment,
                                            tabContent: <div style={{ height: 300, width: '100%', marginBottom: "20px" }}>
                                                <DataGrid rows={table[1].rows2} columns={table[1].cols2} pageSize={table[1].rows2.length} />
                                            </div>
                                        },
                                        {
                                            tabName: "Fixtures",
                                            tabIcon: Assignment,
                                            tabContent: <div style={{ height: 300, width: '100%', marginBottom: "20px" }}>
                                                <div>
                                                    <Button color="primary" className={classes.marginRight} style={{ marginBottom: "20px" }} onClick={(e) => {
                                                        setSelectedSport("");
                                                        setSelectedSquads([])
                                                        setClassicModalAddFixture(true)
                                                    }}>
                                                        Add Fixture
                                            </Button>
                                                </div>
                                                <DataGrid rows={table[2].rows3} columns={table[2].cols3} pageSize={table[2].rows3.length} />
                                            </div>
                                        },
                                        {
                                            tabName: "Stats",
                                            tabIcon: Assignment,
                                            tabContent: <div style={{ height: 300, width: '100%', marginBottom: "20px" }}>
                                                <DataGrid rows={table[3].rows4} columns={table[3].cols4} pageSize={table[3].rows4.length} onEditCellChangeCommitted={onEditCellStat} />
                                            </div>
                                        }
                                    ]}
                                /> : null
                        }

                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>

                        <Button type="submit" color="transparent">Submit</Button>

                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
     </Button>
                    </DialogActions>
                </form>

            </Dialog>
            {/*Edit Model End*/}
            {/*Add Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAdd}
                keepMounted
                onClose={() => setClassicModalAdd(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAdd(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Add League</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(onSubmitAdd)}>
                    <DialogContent>

                        <CustomInput
                            name="name"
                            inputrefs={register2({ required: true })}
                            labelText="Name"
                            id="name2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                        <CustomInput
                            name="profile_rank"
                            inputrefs={register2({ required: true })}
                            labelText="Verified Step"
                            id="profile_rank"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: 0
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>


                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAdd(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Model End*/}
            {/*Add Fixture Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAddFixture}
                keepMounted
                onClose={() => setClassicModalAddFixture(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAddFixture(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Add Fixture</p>
                </DialogTitle>
                <form onSubmit={handleSubmit3(onSubmitAddFixture)}>
                    <DialogContent>
                        <CustomInput
                            name="date"
                            inputrefs={register3({ required: true })}
                            labelText="Date"
                            id="date"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "date",
                                autoComplete: "off",
                            }}
                        />
                        <CustomInput
                            name="home_name"
                            inputrefs={register3({ required: true })}
                            labelText="Home Team Name"
                            id="home_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                        <CustomInput
                            name="away_name"
                            inputrefs={register3({ required: true })}
                            labelText="Away Team Name"
                            id="away_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />

                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAddFixture(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Fixture Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
