import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";

import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import style from './change-password.module.scss';


import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function ChangePasswordComponent(props) {
    const classes = useStyles();

    return (
        <>
            {
                props.isFromChangePasswordPage &&
                <>
                    <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className={classes.inputAdornmentIcon}>
                                        lock_outline
                            </Icon>
                                </InputAdornment>
                            ),
                            type: "password",
                            autoComplete: "off",
                            name: "password",
                            value: props.formState.password,
                            onChange: (event) => { props.onHandleChange(event) }
                        }}
                    />
                    {
                        props.isButtonClick &&
                        <Danger> {props.errorState.password} </Danger>
                    }

                </>

            }

            <CustomInput
                labelText="New Password"
                id="newPassword"


                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                            </Icon>
                        </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    name: "newPassword",
                    value: props.formState.newPassword,
                    onChange: (event) => { props.onHandleChange(event) }
                }}
            />
            {
                props.isButtonClick &&
                <Danger> {props.errorState.newPassword} </Danger>
            }

            <CustomInput
                labelText="Confirm Password"
                id="confirmPassword"


                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                            </Icon>
                        </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    name: "confirmPassword",
                    value: props.formState.confirmPassword,
                    onChange: (event) => { props.onHandleChange(event) }
                }}
            />
            {
                props.isButtonClick &&
                <Danger> {props.errorState.confirmPassword} </Danger>
            }

            { props.isFromChangePasswordPage &&
                <Button
                      onClick={props.handleClick} 
                    color="primary" className={style.submitButton}>
                    Submit
          </Button>
            }
        </>
    );

};


export default ChangePasswordComponent;