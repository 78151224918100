import React, { useEffect, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import paymentService from "services/payment";
import Badge from "components/Badge/Badge.js";
import moment from "moment";

const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const ImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/ImageUpload.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const SquadContainer = (props) => {
  const [data, setData] = React.useState([]);
  const classes = useStyles();

  const getColor = (status) => {
    switch (status) {
      case 0:
        return "warning";
      case 1:
        return "success";
      // case 2:
      //   return "info";
      // case 3:
      //   return "danger";
      default:
        return "warning";
    }
  };

  const getTheTitle=(status)=>{
    switch (status) {
        case 0:
          return "Pending";
        case 1:
          return "Success";
        // case 2:
        //   return "Pending";
        // case 3:
        //   return "Failed";
        default:
          return "Pending";
      }
  }

  useEffect(() => {
    (async () => {
      var paymentList = await paymentService.paymentList();
      if (paymentList && paymentList.data) {
        setData(
          paymentList.data.map((prop, key) => {
            const statusValue = getColor(prop.is_paid)
            const statustitle= getTheTitle(prop.is_paid)
            return {
              id: key,
              squad:(
                <div className="payment_squad_td" style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)",justifyContent:"center",flexDirection:"row",alignItems:"baseline",columnGap:"10px"}}>
                  <div>

                  <img src={prop.squad_image} alt={"no squad Image"}  style={{minWidth:"50px",maxWidth:'50px',minHeight:"50px",maxHeight:"50px"}}/>
                  </div>
                  <div>

                  <p>{prop.squad_name}</p>
                  </div>
                </div>
              ),
              // squad_image: <img src={prop.squad_image} alt={"no squad Image"}  style={{minWidth:"50px",maxWidth:'50px',minHeight:"50px",maxHeight:"50px"}}/>,
              // squad_name: prop.squad_name,
              
              event_name:<div style={{textAlign:"center"}}>{prop.event_name ?? "-"}</div>,
              fees: `${prop.currency == "gbp" ? "£":"€"} ${prop.amount}`,
              transcation_id: prop.transcation_id,
              idcode: prop.id,
              username: prop.username,
              fee_type: prop.fee_type == 1 ? "Event Fee" : prop.fee_type == 2 ? "Request Payment":"" ,
              date:<div>{moment(prop.date).format("DD/MM/YYYY")}</div>,
              status: (
                <div style={{textAlign:"center"}}><Badge color={statusValue}>{statustitle}</Badge></div>
              ),
            };
          })
        );
      }
    })();
  }, []);


  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h4 className={classes.cardIconTitle}>Payment Report</h4>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "ID Code",
                    accessor: "idcode",
                  },
                  {
                    Header: "Squad",
                    accessor: "squad",
                  },
                  {
                    Header: "Event Name",
                    accessor: "event_name",
                  },
                  {
                    Header: "User Name",
                    accessor: "username",
                  },
                  {
                    Header: "Fees Paid",
                    accessor: "fees",
                  },
                  {
                    Header: "Fees Type",
                    accessor: "fee_type",
                  },
                  {
                    Header: "Date",
                    accessor: "date",
                  },
                  {
                    Header: "Transaction Id",
                    accessor: "transcation_id",
                  },
                  {
                    Header: "Transaction Status",
                    accessor: "status",
                  },
                  {
                    Header:"",
                    accessor:"as",
                  }
                ]}
                data={data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
