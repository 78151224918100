import { SET_CHANGE_PROFILE_SUCCESS } from "../../redux/actionTypes";

export default function ChangeProfile(
    state = {
        isSuccess: false
    },
    action
) {
    switch (action.type) {
        case SET_CHANGE_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.Value
            });
      
        default:
            return state;
    }
}