import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import ChangePasswordComponent from "../ChangePasswordComponent/index";

const useStyles = makeStyles(styles);

function ResetPasswordComponent(props) {

    // function handleChngeEvent(event) {
    //     props.onHandleChange(event);
    // }

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <Card login className={classes[props.cardAnimaton]}>
                           
                            <CardBody>
                                <center>
                                    <h4 >  Reset Password </h4>
                                </center>
                                <ChangePasswordComponent
                                 onHandleChange={props.onHandleChange}
                                 formState={props.formState}
                                 errorState={props.errorState}
                                 isButtonClick={props.isButtonClick}
                                 isFromChangePasswordPage={false}
                                />
                               
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" onClick={props.onSubmit}>
                                    Submit
                    </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );

};

export default ResetPasswordComponent;

