// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getLeagueList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/all_leagues';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getLeagueDetails = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/get-league-details?league_id='+id;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getSquadList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/all_squads';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const createLeague = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/create_league';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const addFixture = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/add-fixture';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editLeague = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/edit_league/'+id;
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editLeagueTable = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/edit-league-table';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deleteLeague = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/delete_league/'+id;
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editLeagueStats = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/league/edit-league-stat';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {

  getLeagueList,
  getLeagueDetails,
  createLeague,
  editLeague,
  getSquadList,
  addFixture,
  editLeagueTable,
  editLeagueStats,
  deleteLeague

};

export default apiService;
