import LoginPage from "containers/Login/index.js";
import ForgotPasswordPage from "containers/ForgotPassword/index.js";
import ResetPasswordPage from "containers/ResetPassword/index.js";
import UserProfilePage from "containers/UserProfile/index.js";
import LogoutPage from "containers/Logout/index.js";
import UserPage from "containers/UsersContainer/index.js";
import SquadPage from "containers/Squads/index.js";
import SquadTypePage from "containers/SquadType/index.js";
import PlayerPositionPage from "containers/PlayerPosition/index.js";
import ProfileColourPage from "containers/ProfileColour/index.js";
import ReportsPage from "containers/Reports/index.js";
import PostReport from "containers/PostReport/index.js";
import PageReport from "containers/PageReport/index.js";
import UserReport from "containers/UserReport/index.js";
import AnalyticsPage from "containers/Analytics/index.js";
import PagesPage from "containers/Pages/index.js";
import LeaguePage from "containers/League/index.js";
import EventPage from "containers/Events/index.js";
import PageNotFound from "components/pageNotFound.js";
import PaymentReportContainer from 'containers/PaymentReport/index.js'
import CMSContainer from 'containers/CMS/index.js'
import ApiVersionContainer from 'containers/ApiVersion/index.js'
var dashRoutes = [
  {
    path: "/logout-page",
    name: "",
    component: LogoutPage,
    layout: "/auth",
    
  },
  {
    path: "/login-page",
    name: "",
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/forgot-password-page",
    name: "",
    component: ForgotPasswordPage,
    layout: "/auth"
  },
  {
    path: "/reset-password-page",
    name: "",
    component: ResetPasswordPage,
    layout: "/auth"
  },
  {
    path: "/not-found",
    name: "",
    component: PageNotFound,
    layout: "/auth"
  },
  {
    path: "/user-page",
    name: "",
    component: UserProfilePage,
    layout: "/admin"
  },

  {
    path: "/users",
    name: "Users",
    mini: "US",
    component: UserPage,
    layout: "/admin"
  },
  {
    path: "/user-pages",
    name: "Pages",
    mini: "P",
    component: PagesPage,
    layout: "/admin"
  },
  // {
  //   path: "/sports",
  //   name: "Sports",
  //   mini: "SP",
  //   // icon: DashboardIcon,
  //   component: SportsPage,
  //   layout: "/admin"
  // },
  {
    path: "/league",
    name: "League",
    mini: "LG",
    component: LeaguePage,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    mini: "E",
    component: EventPage,
    layout: "/admin"
  },
  {
    path: "/squads",
    name: "Squads",
    mini: "SQ",
    // icon: DashboardIcon,
    component: SquadPage,
    layout: "/admin"
  },
  {
    path: "/squad-types",
    name: "Squad Types",
    mini: "SQT",
    // icon: DashboardIcon,
    component: SquadTypePage,
    layout: "/admin"
  },
  // {
  //   path: "/cms",
  //   name: "CMS",
  //   mini: "CMS",
  //   // icon: DashboardIcon,
  //   component: CMSContainer,
  //   layout: "/admin"
  // },
  {
    path: "/player-position",
    name: "Player Position",
    mini: "PP",
    // icon: WidgetsIcon,
    component: PlayerPositionPage,
    layout: "/admin"
  },
  {
    path: "/profile-colour",
    name: "Profile Colour",
    mini: "PC",
    // icon: Timeline,
    component: ProfileColourPage,
    layout: "/admin"
  },
  // {
  //   path: "/feeds",
  //   name: "Feeds",
  //   mini: "F",
  //   component: FeedsPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/Squad-players",
  //   name: "Squad And Players",
  //   mini: "SAP",
  //   component: SquadAndPlayersPage,
  //   layout: "/admin"
  // },
  {
    collapse: true,
    path: "/reports",
    name: "Reports",
    mini: "R",
    layout: "/admin",
    views: [
      {
        path: "/post-reports",
        name: "Post Reports",
        mini: "PR",
        component: PostReport,
        layout: "/admin"
      },
      {
        path: "/user-reports",
        name: "User Reports",
        mini: "UR",
        component: UserReport,
        layout: "/admin"
      },
      // {
      //   path: "/page-reports",
      //   name: "Page Reports",
      //   mini: "PGR",
      //   component: PageReport,
      //   layout: "/admin"
      // }
    ]
  },
  {
    path: "/admin/payment",
    name: "Payment Report",
    mini: "PR",
    component: PaymentReportContainer,
    layout: "/admin"
  },
  {
    path: "/apiVersion",
    name: "API Version",
    mini: "AV",
    component: ApiVersionContainer,
    layout: "/admin"
  },
  {
    path: "/analytics",
    name: "Analytics",
    mini: "A",
    component: AnalyticsPage,
    layout: "/admin"
  }
];
export default dashRoutes;
