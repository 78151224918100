import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChangePasswordComponent from "components/ChangePasswordComponent/index.js";
import ModalComponent from "components/Modal/index.js";
import * as changePasswordAction from "./actions.js";
import * as ModalAction from "../../redux/modalAction";
import { getValue, PASSWORD_PATTERN, isEmpty, isValueValid } from "utilities/app.js";
import DialogContentText from '@material-ui/core/DialogContentText';
import history from "../../history";


const ChangePasswordContainer = props => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [formStateForUserInput, setFormStateForUserInput] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [errorStateForUserInput, setErrorStateForUserInput] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (props.isChangePasswordSuccess) {
            props.ModalAction.setCloseModal();
        }

    }, [props.isChangePasswordSuccess]);




    const onHandleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let formData = { ...formStateForUserInput };
        formData[name] = value;
        setFormStateForUserInput(formData);
        validateData(name, value);
    };




    const validateData = (name, value) => {
        let errorObject = errorStateForUserInput;
        switch (name) {
            case "password":
                errorObject.password = "";
                if (isEmpty(value)) {
                    errorObject.password = "Password is Required";
                }
                break;

            case "newPassword":
                errorObject.newPassword = "";
                if (isEmpty(value)) {
                    errorObject.newPassword = "New Password is Required";
                }
                else if (!isValueValid(value.trim(), PASSWORD_PATTERN)) {
                    errorObject.newPassword = "New Password must be minimum eight characters, at least one letter, one number and one special character";
                }
                else if (value == formStateForUserInput.password) {
                    errorObject.newPassword = "Password and New Password should not be same  ";
                }
                break;

            case "confirmPassword":
                errorObject.confirmPassword = "";
                if (isEmpty(value)) {
                    errorObject.confirmPassword = "Confirm Password is Required";
                }
                else if (value != formStateForUserInput.newPassword) {
                    errorObject.confirmPassword = "New Password and Confirm Password should be same  ";
                }
                break;

        };
        setErrorStateForUserInput(errorObject);
    };


    const isAllFieldsValid = () => {

        let formErrors = errorStateForUserInput;
        let isAllValid = true;
        for (let fieldName in formErrors) {
            validateData(fieldName, formStateForUserInput[fieldName]);

            if (formErrors[fieldName].length > 0) {
                isAllValid = false;
            }
        }
        return isAllValid;
    }



    function onButtonClick() {
        setIsFormSubmitted(true);
        if (isAllFieldsValid()) {
            let data = {};
            data.user_id = getValue(props, "props.userData.userId");
            data.password = formStateForUserInput.password;
            data.new_password = formStateForUserInput.newPassword;
            data.confirm_password = formStateForUserInput.confirmPassword;
            props.changePasswordAction.doChangePassword(data);

        }
    }

    function onModalCloseAction() {
        resetFormData();
        props.ModalAction.setCloseModal();

    }

    function resetFormData() {
        setIsFormSubmitted(false);


        let defaultValueForForm = {
            password: '',
            newPassword: '',
            confirmPassword: ''
        };

        let defaultValueForError = {
            password: '',
            newPassword: '',
            confirmPassword: ''
        };

        setFormStateForUserInput(defaultValueForForm);
        setErrorStateForUserInput(defaultValueForError);
    }

    return (
        <>
            {
                props.openModal &&
                <ModalComponent
                    title="Change Password"
                    open={props.openModal}
                    // handleClick={onButtonClick}
                    onClose={onModalCloseAction}
                >

                    <ChangePasswordComponent
                        // cardAnimaton={cardAnimaton}
                        onHandleChange={onHandleChange}
                        formState={formStateForUserInput}
                        errorState={errorStateForUserInput}
                        isButtonClick={true}
                        isFromChangePasswordPage={true}
                        handleClick={onButtonClick}
                    />
                </ModalComponent>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
        openModal: state.modal.open,
        isChangePasswordSuccess: state.changePassword.isSuccess,
        userData: state.masterUser.userDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePasswordAction: bindActionCreators(changePasswordAction, dispatch),
        ModalAction: bindActionCreators(ModalAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);
