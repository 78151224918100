import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="rose" icon>
                            {/* <CardIcon color="rose">
                <PermIdentity />
              </CardIcon> */}
                            <h4 className={classes.cardIconTitle}>
                                Profile
              </h4>
                        </CardHeader>
                        <CardBody>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="User Name"
                                        id="user-name"
                                        error={props.errorState.username}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: "username",
                                            value: props.formState.username,
                                            onChange: (event) => { props.onHandleChange(event) }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Name"
                                        id="name"
                                        error={props.errorState.name}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: "name",
                                            value: props.formState.name,
                                            onChange: (event) => { props.onHandleChange(event) }
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Email (disabled)"
                                        id="email-disabled"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true
                                        }}
                                        inputProps={{
                                            name: "email",
                                            value: props.formState.email
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <Button color="rose" className={classes.updateProfileButton} onClick={props.onSubmit}>
                                Update Profile
              </Button>
                            <Clearfix />
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>
        </div>
    );
}
