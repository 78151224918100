import * as actionTypes from './actionTypes';

export default function toastComponentReducer(state = {
    isSuccessMessage: false,
    isErrorMessage: false,
    isWarningMessage: false,
    isDefaultErrorMessage: false,
    message: ''
}, action) {
    switch (action.type) {
        case actionTypes.SET_SUCCESS_MESSAGE:
            return Object.assign({}, state, {
                isSuccessMessage: action.isSuccessMessage,
                isErrorMessage: action.isErrorMessage,
                isWarningMessage: action.isWarningMessage,
                message: action.message
            });
        case actionTypes.SET_ERROR_MESSAGE:
            return Object.assign({}, state, {
                isErrorMessage: action.isErrorMessage,
                isSuccessMessage: action.isSuccessMessage,
                isWarningMessage: action.isWarningMessage,
                message: action.message
            });
        case actionTypes.SET_WARNING_MESSAGE:
            return Object.assign({}, state, {
                isWarningMessage: action.isWarningMessage,
                isSuccessMessage: action.isSuccessMessage,
                isErrorMessage: action.isErrorMessage,
                message: action.message
            });
        case actionTypes.SHOW_DEFAULT_ERROR_MESSAGE:
            return Object.assign({}, state, {
                isDefaultErrorMessage: action.isDefaultErrorMessage,
                isSuccessMessage: action.isSuccessMessage,
            });
        default:
            return state;
    }
}