import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginAction from "../Login/actions.js";
import * as masterUserAction from "../../redux/masterUserAction";
import history from "../../history";


const LogoutContainer = props => {
  

    useEffect(() => {
       localStorage.clear();
       props.loginAction.resetLoginData();
       props.masterUserAction.resetUserData();
       history.push("/auth/login-page");

    }, []);

    return null;


};

const mapStateToProps = state => {
    return {
     
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch),
        masterUserAction: bindActionCreators(masterUserAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
