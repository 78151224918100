import { } from "../../redux/actionTypes";

export default function forgotPassword(
    state = {
       
    },
    action
) {
    switch (action.type) {
       
        default:
            return state;
    }
}