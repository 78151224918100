import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserProfileComponent from "components/UserProfileComponent/index.js";
// import history from "../../history";
import * as reportsActions from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";

const ReportsContainer = props => {
     const [isButtonClick, setButtonClick] = useState(false);

    const [formStateForUserInput, setFormStateForUserInput] = useState({
       
    });

    const [errorStateForUserInput, setErrorStateForUserInput] = useState({
       
    });

   
    const onHandleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let formData = { ...formStateForUserInput };
        formData[name] = value;
        setFormStateForUserInput(formData);
        validateData(name, value);
    };




    const validateData = (name, value) => {
        let errorObject = errorStateForUserInput;
        switch (name) {

            case "username":
                errorObject.username = "";
                if (isEmpty(value)) {
                    errorObject.username = "User Name required!";
                }
                else if (!isValueValid(value.trim(), ALPHABETS_NUMBER_ONLY)) {
                    errorObject.username = "Please Enter Valid  User Name ";
                }
                break;

            case "name":
                errorObject.name = "";
                if (isEmpty(value)) {
                    errorObject.name = "Name required!";
                }
                else if (!isValueValid(value.trim(), ALPHABETS_ONLY_WITH_SPACE)) {
                    errorObject.name = "Please Enter Valid  Name ";
                }
                break;

            case "email":
                errorObject.email = "";
                if (isEmpty(value)) {
                    errorObject.email = "Email id required!";
                }
                else if (!isValueValid(value.trim(), EMAIL_PATTERN)) {
                    errorObject.email = "Please Enter Valid  Email id";
                }
                break;



        };
        setErrorStateForUserInput(errorObject);
    };


    const isAllFieldsValid = () => {

        let formErrors = errorStateForUserInput;
        let isAllValid = true;
        for (let fieldName in formErrors) {
            validateData(fieldName, formStateForUserInput[fieldName]);

            if (formErrors[fieldName].length > 0) {
                isAllValid = false;
            }
        }
        return isAllValid;
    }



    function onButtonClick() {
        // setButtonClick(true);
        if (isAllFieldsValid()) {
            let data = {};
            Object.keys(formStateForUserInput).forEach((dta) => {
                data[dta] = formStateForUserInput[dta];

            });
            data.user_id = getValue(props, "props.userData.userId");
            data.status = 1;
            props.reportsActions.updateProfile(data);

        }
    }

    return (
        <>
           <div>
               <h1>Reports</h1>
               </div> 
           
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
       reportsActions: bindActionCreators(reportsActions, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
