import apiService from "../../services/apiService";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE_ON_LOGIN, getValue, TOAST_MESSAGE_TIMEOUT } from "../../utilities/app";
import { SET_CHANGE_PROFILE_SUCCESS } from "redux/actionTypes";


export function changeProfileSuccess(Value) {
    return {
      type: SET_CHANGE_PROFILE_SUCCESS,
      Value
    }
  }

export const doChangePassword = (data) => {
    return (dispatch) => {
        // 1. Calling showLoader() method to display loader...
        dispatch(loaderActions.showLoader());
        return apiService.changePassword(data)
            .then(result => {
                dispatch(loaderActions.hideLoader());
                if (result.status) {
                    dispatch(toastComponentAction.showSuccessMessage(true, result.message));
                    setTimeout(() => {
                        dispatch(toastComponentAction.showSuccessMessage(false, ""));
                    }, TOAST_MESSAGE_TIMEOUT);
                    dispatch(changeProfileSuccess(true));

                } else {
                    dispatch(toastComponentAction.showErrorMessage(true, result.message));
                    setTimeout(() => {
                        dispatch(toastComponentAction.showErrorMessage(false, ""));
                    }, TOAST_MESSAGE_TIMEOUT);
                    dispatch(changeProfileSuccess(false));
                }
            }, error => {
                dispatch(loaderActions.hideLoader());
                dispatch(toastComponentAction.showErrorMessage(true, DEFAULT_ERROR_MESSAGE));
                setTimeout(() => {
                    dispatch(toastComponentAction.showErrorMessage(false, ""));
                }, TOAST_MESSAGE_TIMEOUT);
                dispatch(changeProfileSuccess(false));

            });
    }
};