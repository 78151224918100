import apiService from "../../services/apiService";
import { SET_LOGIN_SUCCESS, SET_USER_TOKEN, RESET_LOGIN_DATA } from "../../redux/actionTypes";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import * as masterUserAction from "../../redux/masterUserAction";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE_ON_LOGIN, getValue, TOAST_MESSAGE_TIMEOUT } from "../../utilities/app";


export function resetLoginData(){
  return {
    type: RESET_LOGIN_DATA
  }
}



export function setUserToken(token) {
  return {
    type: SET_USER_TOKEN,
    token
  }
}

export function loginSuccess(loginValue) {
  return {
    type: SET_LOGIN_SUCCESS,
    loginValue
  }
}

export const doLogin = (loginData) => {
  return (dispatch) => {
    // 1. Calling showLoader() method to display loader...
    dispatch(loaderActions.showLoader());
    return apiService.login(loginData)
      .then(result => {
        dispatch(loaderActions.hideLoader());
        if (result.status) {
          let userId = getValue(result, "result.data.id");
          // let usrData = {};
          // usrData.userId = userId;
          // usrData.email = getValue(result, "result.data.email");
          // usrData.username = getValue(result, "result.data.username");
          // usrData.name = getValue(result, "result.data.name");
          // usrData.contact_number = getValue(result, "result.data.contact_number");
          let token = getValue(result, "result.data.token");
          localStorage.setItem("access_token", token);
       
          dispatch(toastComponentAction.showSuccessMessage(true, DEFAULT_SUCCESS_MESSAGE_ON_LOGIN));
          dispatch(setUserToken(token));
          dispatch(masterUserAction.setUserId(userId));
          dispatch(loginSuccess(true));

        } else {
          dispatch(toastComponentAction.showErrorMessage(true, result.message));
          dispatch(loginSuccess(false));

        }
      })
      .catch( error => {
        console.log("error ==>", error.response.data.message);
        dispatch(loaderActions.hideLoader());
        dispatch(toastComponentAction.showErrorMessage(true, error.response.data.message ? error.response.data.message : DEFAULT_ERROR_MESSAGE));
        dispatch(loginSuccess(false));

      });
  }
};