
import * as actionTypes from './actionTypes';

/**
 * This loader-actions has methods to show and close the loader providing
 * relevant actions...
 * For it's use please check doLogin() function of login-actions.js
 */
let loaderCounter = 0;
export function showLoader() {
	loaderCounter++;
	return {
		type: actionTypes.SHOW_LOADER
	}
}
export function hideLoader(from) {
	loaderCounter--;
	return {
	  type: (loaderCounter > 0) ? actionTypes.SHOW_LOADER : actionTypes.HIDE_LOADER
	}
}
