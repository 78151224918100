import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginComponent from "components/Login/index.js";
// import history from "../../history";
import * as forgotPasswordAction from "./actions.js";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid } from "utilities/app.js";

const LoginContainer = props => {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [isButtonClick, setButtonClick] = useState(false);

  const [formStateForUserInput, setFormStateForUserInput] = useState({
    email: ''
  });

  const [errorStateForUserInput, setErrorStateForUserInput] = useState({
    email: ''
  });

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const onHandleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let formData = { ...formStateForUserInput };
    formData[name] = value;
    setFormStateForUserInput(formData);
    validateData(name, value);
  };




  const validateData = (name, value) => {
    let errorObject = errorStateForUserInput;
    switch (name) {
      case "email":
        errorObject.email = "";
        if (isEmpty(value)) {
          errorObject.email = "Email id required!";
        }
        else if (!isValueValid(value.trim(), EMAIL_PATTERN)) {
          errorObject.email = "Please Enter Valid  Email id";
        }
        break;
     

    };
    setErrorStateForUserInput(errorObject);
  };


  const isAllFieldsValid = () => {

    let formErrors = errorStateForUserInput;
    let isAllValid = true;
    for (let fieldName in formErrors) {
      validateData(fieldName, formStateForUserInput[fieldName]);

      if (formErrors[fieldName].length > 0) {
        isAllValid = false;
      }
    }
    return isAllValid;
  }



  function onButtonClick() {
    setButtonClick(true);
    if (isAllFieldsValid()) {

      let data = {};
      Object.keys(formStateForUserInput).forEach((dta) => {
        data[dta] = formStateForUserInput[dta];

      });
      props.forgotPasswordAction.doForgotPassword(data);
      setFormStateForUserInput({email: ""})
    }
  }

  return (
    <>
      <LoginComponent
        onSubmit={onButtonClick}
        cardAnimaton={cardAnimaton}
        onHandleChange={onHandleChange}
        formState={formStateForUserInput}
        errorState={errorStateForUserInput}
        isButtonClick={isButtonClick}
        isFromLoginPage={false}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPasswordAction: bindActionCreators(forgotPasswordAction, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
