import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/squad";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { findLast } from "lodash";
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const SquadContainer = props => {
    const [alert, setAlert] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [master, setMaster] = React.useState({});
    const [leagues, setLeagues] = React.useState([]);
    const [adminList, setAdminList] = React.useState([]);
    const [selectedAdminList, setSelectedAdminList] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState("");
    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedLeague, setSelectedLeague] = React.useState("");
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2 } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalAdd, setClassicModalAdd] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const classes = useStyles();
    const classesalert = useStylesAlert();
    useEffect(() => {
        (async () => {
            var squads = await squadService.getSquadList()
            console.log("resp ==>", data);
            if (squads && squads.data) {
                setData(squads.data.map((prop, key) => {
                    console.log("prop ==>", prop);
                    return {
                        id: key,
                        name: prop.name,
                        idcode: prop.id,
                        league: prop.league_name ? prop.league_name : prop.internal_league,
                        members: prop.member_cnt,
                        profile_rank: prop.profile_rank,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        reset({
                                            name: prop.name,
                                            league_name: prop.league_name,
                                            league_link: prop.league_link,
                                            profile_rank: prop.profile_rank
                                        })
                                        console.log("props in edit ==>", prop);
                                        setActiveData({ ...prop });
                                        setSelectedColor(prop.color_id);
                                        setSelectedSport(prop.sport_id);
                                        setSelectedLeague(prop.league_id ? prop.league_id : "");
                                        setSelectedAdminList(prop.admin_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => warningWithConfirmAndCancelMessage({squad_id: prop.id})}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }

            var masterData = await masterService.getMasterData()
            console.log("masterData ==>", masterData);
            if (masterData && masterData.data) {
                setMaster(masterData.data)
            }
            var leagueData = await masterService.getLeagueList()
            console.log("leagueData ==>", leagueData);
            if (leagueData && leagueData.data) {
                setLeagues(leagueData.data)
            }
            var userData = await userService.getUserList()
            console.log("userData ==>", userData);
            if (userData && userData.data) {
                setAdminList(userData.data)
            }
        })()
    }, [])
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async () => {
        var squads = await squadService.getSquadList()
        console.log("resp ==>", data);
        if (squads && squads.data) {
            setData(squads.data.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    id: key,
                    name: prop.name,
                    idcode: prop.id,
                    league: prop.league_name ? prop.league_name : prop.internal_league,
                    members: prop.member_cnt,
                    profile_rank: prop.profile_rank,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {

                                    setActiveData({ ...prop });
                                    setSelectedColor(prop.color_id);
                                    setSelectedSport(prop.sport_id);
                                    setSelectedLeague(prop.league_id ? prop.league_id : "");
                                    setSelectedAdminList(prop.admin_ids.split(","))
                                    setClassicModal(true)
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => warningWithConfirmAndCancelMessage({squad_id: prop.id})}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }
    const onSubmit = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else if (isEmpty(selectedColor)) {
            props.toastComponentAction.showErrorMessage(true, "Select color is compulsary.")
        } else if (selectedAdminList.length == 0) {
            props.toastComponentAction.showErrorMessage(true, "Select atleast one squad admin.")
        } else if (isEmpty(selectedLeague) && isEmpty(data.league_name)) {
            props.toastComponentAction.showErrorMessage(true, "Select league or enter external league details.")
        } else {
            var final = new FormData();
            final.append("name", data.name);
            final.append("profile_rank", data.profile_rank);
            final.append("sport_id", selectedSport);
            final.append("color_id", selectedColor);
            final.append("admin_ids", selectedAdminList.join());
            console.log("league ==>", selectedLeague, selectedAdminList);
            if (selectedLeague) {
                final.append("league_id", selectedLeague);
            } else {
                final.append("league_name", data.league_name)
                final.append("league_link", data.league_link)
            }
            console.log("badge ==>", profile_pic);
            if (profile_pic) {
                final.append("squad_badge", profile_pic)
            }
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.editSquad(final, activeData.id);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModal(false)
            } catch (err) {
                console.log("err ==>", err);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const onSubmitAdd = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else if (isEmpty(selectedColor)) {
            props.toastComponentAction.showErrorMessage(true, "Select color is compulsary.")
        } else if (selectedAdminList.length == 0) {
            props.toastComponentAction.showErrorMessage(true, "Select atleast one squad admin.")
        } else if (isEmpty(selectedLeague) && isEmpty(data.league_name)) {
            props.toastComponentAction.showErrorMessage(true, "Select league or enter external league details.")
        } else {
            var final = new FormData();
            final.append("name", data.name);
            final.append("profile_rank", data.profile_rank);
            final.append("sport_id", selectedSport);
            final.append("color_id", selectedColor);
            final.append("admin_ids", selectedAdminList.join());
            console.log("league ==>", selectedLeague, selectedAdminList);
            if (selectedLeague) {
                final.append("league_id", selectedLeague);
            } else {
                final.append("league_name", data.league_name)
                final.append("league_link", data.league_link)
            }
            console.log("badge ==>", profile_pic);
            if (profile_pic) {
                final.append("squad_badge", profile_pic)
            }
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.createSquad(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModalAdd(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const warningWithConfirmAndCancelMessage = (data) => {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => successDelete(data)}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classesalert.button + " " + classesalert.success}
            cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            You will not be able to revert this!
          </SweetAlert>
        );
      };
      const successDelete = async (data) => {
        props.loaderActions.showLoader();
        var resp = await squadService.deleteSquad({}, data.squad_id)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload();
        hideAlert();
      };
      const hideAlert = () => {
        setAlert(null);
      };
    return (
        <>
        {alert}
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setSelectedColor("");
                            setSelectedSport("");
                            setSelectedLeague("");
                            setSelectedAdminList([])
                            setClassicModalAdd(true)
                        }}>
                            Add Squad
              </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Squad List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "ID Code",
                                        accessor: "idcode"
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "League",
                                        accessor: "league"
                                    },
                                    {
                                        Header: "Members",
                                        accessor: "members"
                                    },
                                    {
                                        Header: "Verified Step",
                                        accessor: "profile_rank"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Edit Squad</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <ImageUpload
                            defaultPic={activeData.squad_badge}
                            avatar
                            addButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            onChangeFile={(file) => {
                                console.log("file ==>", file);
                                setProfilePic(file)
                            }}
                            changeButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            removeButtonProps={{
                                color: "danger",
                                round: true
                            }}
                            button_text="Add Squad Badge"
                        />
                        <CustomInput
                            name="name"
                            inputrefs={register({ required: true })}
                            labelText="Name"
                            id="name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.name}`
                            }}
                        />
                        <CustomInput
                            name="profile_rank"
                            inputrefs={register({ required: true })}
                            labelText="Verified Step"
                            id="profile_rank"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: `${activeData.profile_rank}`
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Color</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.colours && master.colours.length > 0 ?
                                    master.colours.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Admins</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedAdminList}
                                onChange={(e) => {

                                    setSelectedAdminList(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}
                                multiple
                                disabled
                            >
                                {adminList && adminList.length > 0 ?
                                    adminList.map(art => {
                                        return <MenuItem
                                            key={art.id}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.id}
                                        >
                                            {art.name}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select League</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedLeague}
                                onChange={(e) => {
                                    setSelectedLeague(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {leagues && leagues.length > 0 ?
                                    leagues.map(art => {
                                        return <MenuItem
                                            key={art.id}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.id}
                                        >
                                            {art.name}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <CustomInput
                            name="league_name"
                            inputrefs={register({ required: false })}
                            labelText="External league name"
                            id="league_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.league_name}`
                            }}
                        />
                        <CustomInput
                            name="league_link"
                            inputrefs={register({ required: false })}
                            labelText="External league link"
                            id="external_league_link"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
     </Button>
                    </DialogActions>
                </form> : null}

            </Dialog>
            {/*Edit Model End*/}
            {/*Add Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAdd}
                keepMounted
                onClose={() => setClassicModalAdd(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAdd(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Add Squad</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(onSubmitAdd)}>
                    <DialogContent>

                        <ImageUpload
                            avatar
                            addButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            onChangeFile={(file) => {
                                console.log("file ==>", file);
                                setProfilePic(file)
                            }}
                            changeButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            removeButtonProps={{
                                color: "danger",
                                round: true
                            }}
                            button_text="Add Squad Badge"
                        />
                        <CustomInput
                            name="name"
                            inputrefs={register2({ required: true })}
                            labelText="Name"
                            id="name2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                        <CustomInput
                            name="profile_rank"
                            inputrefs={register2({ required: true })}
                            labelText="Verified Step"
                            id="profile_rank"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: 0
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Color</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.colours && master.colours.length > 0 ?
                                    master.colours.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Admins</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedAdminList}
                                onChange={(e) => {

                                    setSelectedAdminList(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}
                                multiple
                            >
                                {adminList && adminList.length > 0 ?
                                    adminList.map(art => {
                                        return <MenuItem
                                            key={art.id}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.id}
                                        >
                                            {art.name}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select League</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedLeague}
                                onChange={(e) => {
                                    setSelectedLeague(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {leagues && leagues.length > 0 ?
                                    leagues.map(art => {
                                        return <MenuItem
                                            key={art.id}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.id}
                                        >
                                            {art.name}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <CustomInput
                            name="league_name"
                            inputrefs={register2({ required: false })}
                            labelText="External league name"
                            id="league_name2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                        <CustomInput
                            name="league_link"
                            inputrefs={register2({ required: false })}
                            labelText="External league link"
                            id="external_league_link2"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                            }}
                        />
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAdd(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
