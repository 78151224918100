
import {
  SET_OPEN_MODAL,
  SET_CLOSE_MODAL
 
} from "./actionTypes";


export function setOpenModal() {
  return {
    type: SET_OPEN_MODAL,
    data : true
  };
}

export function setCloseModal() {
  return {
    type: SET_CLOSE_MODAL,
    data : false
  };
}