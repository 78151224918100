import React, { useEffect, useState } from 'react';


function useUserProfile(userData,getUserProfileData) {

    useEffect(() => {
        if (userData && Object.keys(userData).length == 0) {
            getUserProfileData();
        }

    }, [userData]);


};

export default useUserProfile;


