// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getSquadList = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/event/all_events';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getSquadListReal = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/squad/all_squads';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const createEvent = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/api/v2/event/create-event';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const editEvent = (data = {}, id) => {
  let url = process.env.REACT_APP_API_HOST + '/api/v2/event/edit-event';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deleteEvent = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/api/v2/event/delete-event';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithTokenForApi(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {

  getSquadList,
  createEvent,
  editEvent,
  getSquadListReal,
  deleteEvent

};

export default apiService;
